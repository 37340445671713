import React from "react";
import ReactDOM from "react-dom";
import Etusivu from "./components/etusivu";
import Container from "@mui/material/Container";

import "./styles.css";
function Appi() {
	return (
		<div className="wizard">
			<Etusivu />
		</div>
	);
}

export default Appi;
