import { gql } from "@apollo/client";

export const PalvelualueenVastuualueet = gql`
  query PalvelualueenVastuualueet($palvelualue_id: Int!) {
    PalvelualueenVastuualueet(palvelualue_id: $palvelualue_id) {
      vastuualue_id
      kokonimi
      lyhenne
      lisatieto
    }
  }
`;

export const HaeOsastot = gql`
  query KaikkiOsastot {
    Vastuualueet {
      vastuualue_id
      kokonimi
    }
  }
`;
