//import React from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import PersonIcon from "@material-ui/icons/Person";
import Divider from "@material-ui/core/Divider";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  Container,
  Grid,
  Icon,
  Tooltip,
  FormHelperText,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useState, createContext, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import BasicSelect from "./components/selectPalvelualue";
import BasicSelect2 from "./components/selectVastuualue";
import AlertDialogSlide from "./components/palvelualueDialog";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@mui/material/styles";
import { UserContext } from "../src/components/content";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Toimialueet } from "./services/ToimialueService";
import { ToimialueenPalvelualueet } from "./services/PalvelualueService";
import { PalvelualueenVastuualueet } from "./services/VastuualueService";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const useStyles = makeStyles({
  custom: {
    color: "#FF0000",
    fontWeight: "bold",
  },
});

const tulos = [];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #FF0000",
  boxShadow: 24,
  p: 4,
};

export default function KurssiCard(e) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [iluku, setiluku] = React.useState(false);
  const [toimialuevalittu, setToimialuevalittu] = React.useState(false);
  const toimialueetQuery = useQuery(Toimialueet);
  const [haePalvelualueet, palvelualueQuery] = useLazyQuery(
    ToimialueenPalvelualueet
  );
  const [haeVastuualueet, vastuualueQuery] = useLazyQuery(
    PalvelualueenVastuualueet
  );
  const handleCloseModal3 = () => setOpen3(false);
  const handleCloseModal2 = () => setOpen2(false);
  const handleExpandClick = (props) => {
    setExpanded(!expanded);
    setiluku(true);
    setOpen(true);
    setTitle(props.otsikko);
    //setPalvelualue(props.alue);
    setChecked(props.alue);
    changeAlue(props.alue);
    console.log(props.testi);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSulje = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen2(true);
  };
  const [checked, setChecked] = useState({
    palvelualue_1: false,
    palvelualue_2: true,
    palvelualue_1: false,
    palvelualue_3: false,
    palvelualue_4: false,
    palvelualue_5: false,
  });
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [palvelualue, setPalvelualue] = React.useState(0);
  const [vastuualue, setVastuualue] = React.useState(0);

  const { tiedot, callback } = useContext(UserContext);

  const changeAlue = (e) => {
    setChecked(() => {
      console.log(e);
      switch (e) {
        case 1:
          return { palvelualue_1: true };
        case 2:
          return { palvelualue_2: true };
        case 3:
          return { palvelualue_3: true };
        case 4:
          return { palvelualue_4: true };
        case 5:
          return { palvelualue_5: true };
        default:
          return 0;
      }
    });
  };

  const changeRadio = (e) => {
    setChecked(() => {
      return {
        palvelualue_1: false,
        palvelualue_2: false,
        palvelualue_3: false,
        palvelualue_4: false,
        palvelualue_5: false,
        [e.target.value]: true,
      };
      localStorage.setItem("palvelualue", { checked });
    });
    console.log(e.target.value);
  };

  const avaaValinnat = (props) => {
    setExpanded(!expanded);
    setOpen(true);
    setTitle(props.otsikko);
    haePalvelualueet({ variables: { toimialue_id: props.toimialue } });
  };

  const renderValinnatSisalto = () => {
    if (palvelualueQuery.loading || vastuualueQuery.loading) {
      return <p>Ladataan...</p>;
    }

    if (palvelualueQuery.error) return <p>Virhe haettaessa palvelualueita</p>;
    if (vastuualueQuery.error) return <p>Virhe haettaessa vastuualueita</p>;

    return (
      <>
        <FormControl fullWidth sx={{ paddingTop: 3 }}>
          <InputLabel id="palvelualue_valintateksti" sx={{ paddingTop: 3 }}>
            Palvelualue
          </InputLabel>
          <Select
            value={palvelualue}
            labelId="palvelualue_valintateksti"
            label="Palvelualue"
            spacing={2}
            onChange={(e) => asetaPalvelualue(e.target.value)}
          >
            {palvelualueQuery.data?.ToimialueenPalvelualueet.map(
              (palvelualue) => (
                <MenuItem
                  value={palvelualue.palvelualue_id}
                  key={palvelualue.palvelualue_id}
                >
                  {palvelualue.kokonimi}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ paddingTop: 3 }}>
          <InputLabel id="vastuualue_valintateksti" sx={{ paddingTop: 3 }}>
            Yksikkö
          </InputLabel>
          <Select
            value={vastuualue}
            labelId="vastuualue_valintateksti"
            label="Vastuualue"
            onChange={(e) => asetaVastuualue(e.target.value)}
          >
            {vastuualueQuery.data?.PalvelualueenVastuualueet.map(
              (vastuualue) => (
                <MenuItem
                  value={vastuualue.vastuualue_id}
                  key={vastuualue.vastuualue_id}
                >
                  {vastuualue.kokonimi}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </>
    );
  };

  const asetaPalvelualue = (value) => {
    setPalvelualue(value);
    callback({
      ...tiedot,
      palvelualue_id: value,
    });
    haeVastuualueet({ variables: { palvelualue_id: value } });
  };

  const asetaVastuualue = (value) => {
    setVastuualue(value);
    callback({
      ...tiedot,
      vastuualue_id: value,
    });
  };

  // tähän tulee shadow layout tms. Päälle siis tekstiä alueesta
  const valinta = (e) => {
    if (e === 1) {
      setOpen3(true);
    } else console.log("jaahas");
  };
  const renderToimialueet = () =>
    toimialueetQuery.data.Toimialueet.map((toimialue, index) => {
      const { toimialue_id, nimi, lyhenne, lisatieto, vastaava } = toimialue;
      return (
        <Grid item md={2} key={index}>
          <Fab
            variant="extended"
            size="big"
            color="primary"
            aria-label="add"
            onClick={() => valinta(index)}
          >
            {lyhenne}
          </Fab>

          <Card sx={{ minWidth: 375, maxWidth: 375, marginLeft: 5 }}>
            <CardHeader
              action={
                <IconButton
                  aria-label="settings"
                  name="Petri"
                  setTitle="Petri"
                  onClick={() => {
                    callback({
                      ...tiedot,
                      toimialue_id,
                    });
                    avaaValinnat({
                      otsikko: nimi,
                      toimialue: toimialue_id,
                    });
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              }
              title={nimi}
            />
            <CardContent>
              Lisätieto
              <p>{lisatieto}</p>
              <p>Vastaava: {vastaava}</p>
            </CardContent>
          </Card>
        </Grid>
      );
    });

  if (toimialueetQuery.loading) {
    return <p>Ladataan...</p>;
  }

  if (toimialueetQuery.error) return <p>Virhe haettaessa toimialueita</p>;

  return (
    <div>
      <box>
        <Grid
          container
          columns={{ xs: 10, md: 10 }}
          justifyContent="center"
          alignItems="left"
          spacing={2}
          style={{
            margin: 0,
            width: "100%",
          }}
        >
          {renderToimialueet()}

          <Dialog
            disableEnforceFocus
            open={open}
            title={title}
            arvo={palvelualue}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            PaperProps={{ sx: { width: "40%" } }}
          >
            <DialogTitle id="alert-dialog-New">
              Valitse palvelu- ja vastuualueet
              {renderValinnatSisalto()}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleClose}> Sulje </Button>
              <Button onClick={handleClose}> Valitse </Button>
            </DialogActions>
          </Dialog>
          <AlertDialogSlide />
        </Grid>
      </box>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open3}
          onClose={handleCloseModal3}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open3}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h5"
                component="h2"
                color="primary"
                align="left"
              >
                <p>Keski-Pohjanmaan pelastuslaitos</p>
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Keski-Pohjanmaan hyvinvointialue Soite vastaa alueen
                sote-palveluista ja pelastustoimesta. Jäsenkuntia ovat Kokkola,
                Kannus, Toholampi, Kaustinen, Veteli, Halsua, Perho ja
                Lestijärvi.
                <Typography className={classes.custom}>
                  Aloita tilaus painamalla kolmea pistettä laatikon yläreunasta.
                </Typography>
              </Typography>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open2}
          onClose={handleCloseModal2}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open2}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h5"
                component="h2"
                color="primary"
                align="left"
              >
                <p>Keski-Pohjanmaan pelastuslaitos</p>
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Keski-Pohjanmaan hyvinvointialue Soite vastaa alueen
                sote-palveluista ja pelastustoimesta. Jäsenkuntia ovat Kokkola,
                Kannus, Toholampi, Kaustinen, Veteli, Halsua, Perho ja
                Lestijärvi.
                <Typography className={classes.custom}>
                  Aloita tilaus painamalla kolmea pistettä laatikon yläreunasta.
                </Typography>
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item lg={2}>
          <Fab variant="extended" size="small" color="primary" aria-label="add">
            Terv. sairaanhoito
          </Fab>
          <Divider flexItem />

          <Card id={"1"} key={"1"} sx={{ maxWidth: 4 }}>
            <CardHeader
              action={
                <IconButton
                  aria-label="settings"
                  id={"1"}
                  key={"1"}
                  name="Petri"
                  setTitle="Petri"
                  onClick={() => {
                    callback({
                      ...tiedot,
                      Toimialue: "Terveyden ja sairaanhoidon palvelut",
                    });
                    handleExpandClick({
                      otsikko: "Terveyden ja sairaanhoidon palvelut",
                      alue: 1,
                      testi: 3,
                    });
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              }
              title="Terveyden ja sairaanhoidon palvelut"
            />
            <Box
              sx={{
                mt: 1.5,
                p: 0.5,
                backgroundColor: (theme) =>
                  alpha(theme.palette.warning.dark, 0.7),
                borderRadius: "5px",
                color: "#0066ff",
                fontWeight: "medium",
                display: "flex",
                fontSize: 12,
                alignItems: "center",
                "& svg": {
                  fontSize: 21,
                  mr: 0.5,
                },
              }}
            >
              <ErrorOutlineIcon />
              RESURSSi VAJAUS -15%
            </Box>
            <CardContent>
              Lisätieto
              <Grid>
                <Radio
                  value="palvelualue_1"
                  name="choice"
                  checked={checked.palvelualue_1}
                  onClick={changeRadio}
                />
                Valitse toimialue
              </Grid>
              <br />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title="Sairaanhoitaja">
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
            >
              Vammaispalvelut
            </Fab>
          </Tooltip>
          <Card sx={{ maxWidth: 2 }}>
            <CardHeader
              action={
                <IconButton
                  aria-label="settings"
                  id={"2"}
                  key={"2"}
                  name="Kalle"
                  onClick={() => {
                    handleExpandClick({ otsikko: "Vammaispalvelut", alue: 2 });
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              }
              title="Vammaispalvelut"
              subheader="Toimialueenvastaava:Saila"
            />
            <CardContent>
              {palvelualue}
              <Grid>
                <PersonIcon />
                <Radio
                  value="palvelualue_2"
                  name="choice"
                  checked={checked.palvelualue_2}
                  onClick={changeRadio}
                />
                Valitse toimialue
              </Grid>
              <br />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title="Sairaanhoitaja">
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
            >
              Hoito ja hoiva
            </Fab>
          </Tooltip>
          <Card sx={{ maxWidth: 2 }}>
            <CardHeader
              action={
                <IconButton
                  aria-label="settings"
                  id={"3"}
                  key={"3"}
                  name="Kalle"
                  onClick={() => {
                    handleExpandClick({ otsikko: "Hoito ja hoiv", alue: 3 });
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              }
              title="Hoito ja hoiva"
            />
            <Box
              sx={{
                mt: 1.5,
                p: 0.5,
                backgroundColor: (theme) => alpha(theme.palette.info.dark, 0.7),
                borderRadius: "5px",
                color: "#FFFFFF",
                fontWeight: "medium",
                display: "flex",
                fontSize: 12,
                alignItems: "center",
                "& svg": {
                  fontSize: 21,
                  mr: 0.5,
                },
              }}
            >
              <ErrorOutlineIcon />
              TILAUKSET KÄSITTELYSSÄ
            </Box>

            <CardContent>
              <Grid>
                <Radio
                  value="palvelualue_3"
                  name="choice"
                  checked={checked.palvelualue_3}
                  onClick={changeRadio}
                />
                Valitse toimialue
              </Grid>
              <br />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title="Sairaanhoitaja">
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
            >
              Perh. palvelut
            </Fab>
          </Tooltip>
          <Card sx={{ maxWidth: 2 }}>
            <CardHeader
              action={
                <IconButton
                  aria-label="settings"
                  id={"4"}
                  key={"4"}
                  name="Kalle"
                  onClick={() => {
                    handleExpandClick({
                      otsikko: "Perheiden palvelut",
                      alue: 4,
                    });
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              }
              title="Perheiden palvelut"
            />
            <CardContent>
              Toimialuevastaava: Heidi
              <Grid>
                <PersonIcon />
              </Grid>
              <br />
              <br />
              <br />
              Valitse toimialue
              <Radio
                value="palvelualue_4"
                name="choice"
                checked={checked.palvelualue_4}
                onClick={changeRadio}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title="Palvelualue-5">
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
            >
              Joht/tuotannon palvelut
            </Fab>
          </Tooltip>
          <Card sx={{ maxWidth: 2 }}>
            <CardHeader
              action={
                <IconButton
                  aria-label="settings"
                  id={"5"}
                  key={"5"}
                  name="Kalle"
                  onClick={() => {
                    handleExpandClick({
                      otsikko: "ohtamisen ja tuotannon tukipalvelu",
                      alue: 5,
                    });
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              }
              title=" Joht. ja tuotannon tukipalvelut"
            />
            <CardContent>
              <Grid>
                <PersonIcon />
              </Grid>
              <br />
              Valitse toimialue
              <Radio
                value="palvelualue_5"
                name="choice"
                checked={checked.palvelualue_5}
                onClick={changeRadio}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <div>
        <Dialog
          disableEnforceFocus
          open={open}
          title={title}
          arvo={palvelualue}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
          PaperProps={{ sx: { width: "50%" } }}
        >
          <DialogTitle id="alert-dialog-New">
            {title}
            <Divider />

            <BasicSelect name="petri" tulos={palvelualue} />

            <BasicSelect2
              name="Kalle"
              tulos={palvelualue}
              palue={checked}
            ></BasicSelect2>
          </DialogTitle>
          <Divider />
          <Button onClick={handleClose}> Valitse </Button>
        </Dialog>
      </div>
      <div>
        <AlertDialogSlide />
      </div>
    </div>
  );
}
