import { createContext, Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from "@mui/material/Alert";
import AlertTitle from '@mui/material/AlertTitle';

export const SnackContext = createContext({
    snack: '',
    setSnack: () => {},
  });

  export class SnackProvider extends Component {
    state = {
       snack: null,
       type: null,
       title: null,
    };

    render() {
        return (
            <SnackContext.Provider
                value={{
                    snack: this.state.snack,
                    setSnack: (message, type, title) => this.setState({ snack: message, type, title })
                }}
            >
                {this.props.children}
                <Snackbar open={!!this.state.snack} autoHideDuration={6000} onClose={() => { if (this.state.snack) this.setState({ snack: null, title: null })}}>
                    <Alert elevation={6} variant="filled" title={this.state.title} severity={this.state.type || 'success'} onClose={() => { if (this.state.snack) this.setState({ snack: null, title: null })}} >
                    <AlertTitle>{this.state.title}</AlertTitle>
                        {this.state.snack}
                    </Alert>
                </Snackbar>     
            </SnackContext.Provider>
        );
    }
}
