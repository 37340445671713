//import * as React from "react";
import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Grid, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import DateFnsUtils from "@date-io/date-fns";
import fiLocale from "date-fns/locale/fi";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { UserContext } from "./content";
import { DateTime } from "luxon";
import Autocomplete from "@mui/material/Autocomplete";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { useQuery, useMutation } from "@apollo/client";
import AlertD from "../components/Alert";
//import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { HaeOsastot } from "../services/VastuualueService";
import KaikkiSijaiset from "./KaikkiSijaiset";
import { HaeTilaukset } from "../services/ResurssitilausService";
import { TallennaSopimus } from "../services/TallennaSopimusService";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Sopimukset from "./Sopimukset";
import { Tehtava } from "../services/HaeTeahtavaService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  //const { tiedot, callback } = useContext(UserContext);
  const [open, setOpen] = React.useState(true);
  const [alertopen, setAlertOpen] = React.useState(false);
  const [value, setValue] = React.useState("Controlled");
  const toimialueetQuery = useQuery(HaeOsastot);
  const tilauksetQuery = useQuery(HaeTilaukset);
  const [snack, setSnack] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [type, setType] = React.useState(null);
  const tehtavaQuery = useQuery(Tehtava);
  const [TallennaUusiSopimus, tallennaresult] = useMutation(TallennaSopimus, {
    onCompleted: () => {
      setAutoC2(null);
      tilauksetQuery.refetch();

      setSnack("Sopimus tallennettu!");
      setTitle("Uusi soppari");
      console.log(props.sissi.name);
      setType("success");
      props.paivita();
    },
  });

  const handleAlertOpen = (event) => {
    setAlertOpen(true);
  };
  const [autoC1, setAutoC1] = useState(null);
  const [autoC2, setAutoC2] = useState(null);
  const [aloituspvm, setAloituspvm] = useState(null);
  const [lopetusspvm, setLopetuspvm] = useState(null);
  const [vakanssinro, setVakanssinro] = useState(null);
  const [tehtavat, setTehtavat] = useState([]);
  const [lisatieto, setLisatieto] = useState(null);

  const handleChange = (event) => {
    const value = event.target.value;
    setValue(event.target.value);
    setLisatieto(event.target.value);
  };
  const handleVastuualue = (event, value) => {
    console.log(value.vastuualue_id);
    setAutoC1(value.vastuualue_id);
  };
  const handleResurssiTilaus = (event, value) => {
    setAutoC2(value.resurssitilaus_id);
    console.log(value);
  };
  const handleLisatieto = (e) => {
    setLisatieto(e.target.value);
  };
  const handleVakanssi = (e) => {
    setVakanssinro(e.target.value);
  };
  const handleTallenna = () => {
    if (
      autoC1 !== "" &&
      autoC2 !== "" &&
      selectedDate !== "" &&
      selectedDate2 !== "" &&
      vakanssinro !== ""
    ) {
      const sopimus = {
        sopimus_id: null,
        sijainen_id: props.sissi.id,
        resurssitilaus_id: autoC2,
        vastuualue_id: autoC1,
        alkupvm: DateTime.fromJSDate(selectedDate).toISODate(),
        loppupvm: DateTime.fromJSDate(selectedDate2).toISODate(),
        vakanssinumero: vakanssinro,
        lisatieto: lisatieto,
        aktiivinen: true,
        arkistoitu: false,
        tehtavat: tehtavat.map((t) => t.id),
      };
      console.log(vakanssinro);
      TallennaUusiSopimus({ variables: sopimus });
    }
  };
  const [boxi, setBoxi] = React.useState({
    lifecare: true,
    avaimet: false,
    tyosopimus: false,
  });

  const handleChangeboxi = (checked, tehtava) => {
    if (checked) {
      setTehtavat([
        ...tehtavat,
        {
          id: tehtava.tehtava_id,
        },
      ]);
    } else {
      // remove from list
      setTehtavat(tehtavat.filter((t) => t.tehtava_id !== tehtava.tehtava_id));
    }
  };
  /*
  const handleVakanssinumeroChange = (e) => {
    const value = e.target.value;
    setVakanssinumero(value);
    callback({ ...tiedot, vakanssinumero: value });
  };
  */
  const { lifecare, avaimet, tyosopimus } = boxi;
  //const []
  const [selectedDate, setSelectedDate] = useState(
    null //new Date(localStorage.getItem("date") ?? Date.now())
  );

  const [selectedDate2, setSelectedDate2] = useState(
    null // new Date(localStorage.getItem("date2") ?? Date.now())
  );

  const [selectedDaypart, setDaypart] = useState(
    localStorage.getItem("day-part") ?? "nomatter"
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);

    //callback({ ...tiedot, aloituspvm: DateTime.fromJSDate(date).toISODate() });
  };
  const handleDateChange2 = (date2) => {
    setSelectedDate2(date2);

    // callback({ ...tiedot, lopetuspvm: DateTime.fromJSDate(date2).toISODate() });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (alertopen) {
    return <AlertD />;
    console.log("heips");
  }
  console.log(toimialueetQuery);

  const renderoiTehtavat = () => {
    if (tehtavaQuery.loading || tehtavaQuery.error) {
      return;
    }

    return tehtavaQuery.data.Tehtavat.map((tehtava) => (
      <FormControlLabel
        control={
          <Checkbox
            //checked={false}
            onChange={(e) => handleChangeboxi(e.target.checked, tehtava)}
          />
        }
        key={tehtava.tehtava_id}
        label={tehtava.kokonimi}
      />
    ));
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.kalle}
        onClose={props.close}
        TransitionComponent={Transition}
      >
        <Snackbar
          open={!!snack}
          autoHideDuration={2000}
          onClose={() => {
            if (snack) {
              setSnack(null);
              setTitle(null);
            }

            props.close();
          }}
        >
          <Alert
            elevation={6}
            variant="filled"
            title={title}
            severity={type || "success"}
            onClose={() => {
              if (snack) {
                setSnack(null);
                setTitle(null);
              }
            }}
          >
            <AlertTitle>{title}</AlertTitle>
            {snack}
          </Alert>
        </Snackbar>

        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {`Uusi sopimus:  ${props.sissi.name}`}
            </Typography>
            <Button
              autoFocus
              variant="contained"
              color="error"
              onClick={props.close}
            >
              Sulje
            </Button>
          </Toolbar>
        </AppBar>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Sijainen"
                value={
                  props.sissi ? props.sissi.name : "Ei löydy sijaisen tietoja"
                }
                disabled={true}
                variant="outlined"
                padding="10px"
                margin="dense"
                width="22"
                // value={sukunimi}
                fullWidth
                //onChange={({ target }) => setSukunimi(target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper>
                {" "}
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                  <div>
                    <DatePicker
                      inputProps={{ id: "date" }}
                      value={selectedDate}
                      autoOk
                      format="yyyy-MM-dd"
                      onChange={handleDateChange}
                      name="date"
                      id="date"
                      fullWidth
                      variant="filled"
                      label="Aloitus päivämäärä"
                    />
                  </div>{" "}
                </MuiPickersUtilsProvider>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                <div>
                  <DatePicker
                    inputProps={{ id: "date2" }}
                    value={selectedDate2}
                    autoOk
                    onChange={handleDateChange2}
                    name="date2"
                    id="date2"
                    fullWidth
                    variant="filled"
                    label="Lopetus päivämäärä"
                  />
                </div>{" "}
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={6} sm={6}>
              <Autocomplete
                disablePortal
                onChange={handleVastuualue}
                id="tags-standard"
                options={toimialueetQuery.data?.Vastuualueet}
                getOptionLabel={(option) => {
                  console.log(option);
                  return option.kokonimi;
                }}
                //defaultValue=
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Valitse yksikkö"
                    placeholder="Yksikkö"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Autocomplete
                disablePortal
                onChange={handleResurssiTilaus}
                id="tags-standard"
                options={tilauksetQuery.data?.AvoimetTilaukset}
                              getOptionLabel={(option) => {
                  return `${option.aloituspvm} - ${option.lopetuspvm} ; ${option.tilaajayhteystieto} ;${option.vakanssinumero}; ${option.vastuualue.kokonimi}`;
                }}
                
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Valitse listalta resurssitilaus"
                    placeholder="Favorites"
                    
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="outlined-number"
                label="Vakanssinumero"
                type="tel"
                onChange={handleVakanssi}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="outlined-multiline-flexible"
                label="Lisätietoja"
                multiline
                maxRows={4}
                value={lisatieto}
                onChange={handleChange}
              />
            </Grid>

            <Box sx={{ display: "flex" }}>
              <FormControl
                sx={{ m: 6 }}
                component="fieldset"
                variant="standard"
              >
                <FormLabel component="legend">
                  Sopimukseen liityvät tehtävät/ muut kuin työsopimus
                </FormLabel>
                <FormGroup aria-label="position" row>
                  {renderoiTehtavat()}
                </FormGroup>
              </FormControl>
            </Box>

            <Grid container direction="column" item xs={5} sm={5}></Grid>
            <Grid container direction="column" item xs={5} sm={5}>
              <Button variant="contained" color="error" onClick={props.close}>
                Peruuta
              </Button>
            </Grid>
            <Grid container direction="column" item xs={5} sm={5}>
              <Button variant="contained" onClick={handleTallenna}>
                Tallenna
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </div>
  );
}
