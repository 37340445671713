import { gql } from "@apollo/client";

export const Nimikkeet = gql`
	query Nimikkeet {
		Nimikkeet {
			lyhenne
			kokonimi
			lisatieto
			nimike_id
			nimikeryhma {
				nimikeryhma_id
			}
		}
	}
`;

export const Nimike = gql`
	query Nimike($nimike_id: Int!) {
		Nimike(nimike_id: $nimike_id) {
			nimike_id
			kokonimi
			lyhenne
			lisatieto
			nimikeryhma {
				nimikeryhma_id
			}
		}
	}
`;

export const TallennaNimike = gql`
	mutation TallennaNimike(
		$nimike_id: Int
		$kokonimi: String!
		$lyhenne: String!
		$lisatieto: String
		$nimikeryhma_id: Int!
	) {
		TallennaNimike(
			nimike_id: $nimike_id
			kokonimi: $kokonimi
			lyhenne: $lyhenne
			lisatieto: $lisatieto
			nimikeryhma_id: $nimikeryhma_id
		) {
			kokonimi
			lyhenne
			lisatieto
			nimikeryhma {
				nimikeryhma_id
			}
		}
	}
`;

export const PoistaNimike = gql`
	mutation PoistaNimike($nimike_id: Int!) {
		PoistaNimike(nimike_id: $nimike_id) {
			nimike_id
		}
	}
`;
