import { gql } from "@apollo/client";

export const Osaamiset = gql`
	query Osaamiset {
		Osaamiskartat {
			osaamiskartta_id
			suoritetyyppi
			lisatieto
			kuukausimaara
			nimike {
				nimike_id
			}
		}
	}
`;
