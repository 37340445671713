import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import StepForm from './components/StepForm';
import Header from './components/Header';
import Footer from './components/Footer';

/**
 * Old Js version
 * https://codesandbox.io/s/react-material-ui-step-form-forked-578lz
 *
 * Repo: https://github.com/awran5/react-material-ui-step-form
 */

const theme = createTheme()

const  ResurssiKiinnitys = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Header />
    <Container component='main' maxWidth='sm' sx={{ mb: 4 }}>
      <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <StepForm />
      </Paper>
      <Footer />
    </Container>
  </ThemeProvider>
)

export default ResurssiKiinnitys
