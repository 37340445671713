import { gql } from "@apollo/client";

export const Tehtava = gql`
  query Tehtavat {
    Tehtavat {
      tehtava_id
      kokonimi
      lisatieto
    }
  }
`;
export const TallennaSopimusTehtavat = gql`
  mutation TallennaSopimusTehtava(
    $sopimus_tehtavat: sopimustehtava
    $valmis: Boolean!
  ) {
    TallennaSopimusTehtavat(
      sopimus_tehtava_id: $sopimus_tehtava_id
      valmis: $valmis
    ) {
      sopimus_tehtava_id
      valmis
      tehtava {
        tehtava_id
      }
    }
  }
`;
