import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Fab from "@material-ui/core/Fab";
import Button from "@mui/material/Button";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@mui/material/Tooltip";
import FullSreenD from "./components/FullScreenDialog";
import Avatar from "@mui/material/Avatar";
import PageviewIcon from "@mui/icons-material/Pageview";
import { green, pink, red } from "@mui/material/colors";
import { useQuery } from "@apollo/client";
import { HaeSopimukset } from "./services/SopimusService";
import SopimusTiedot from "./components/SopimusTiedot";

function createData(id, name, calories, carbs) {
  const joku =
    carbs &&
    carbs.length > 1 &&
    carbs.slice(1).map((Sopimukset) => {
      return {
        date: `${Sopimukset.alkupvm} - ${Sopimukset.loppupvm}`,
        customerId: Sopimukset.vastuualue.kokonimi,
        amount: Sopimukset.vakanssinumero,
        sopimus: Sopimukset.lisatieto,
      };
    });
  return {
    id,
    name,
    calories,
    info: 
      carbs && carbs.length > 0
      ? `${carbs[0].lisatieto}`
      : "",
    vastuualue_lyh: 
      carbs && carbs.length > 0
      ? `${carbs[0].vastuualue.lyhenne}`
      : "",
    fat:
      carbs && carbs.length > 0
        ? `${carbs[0].alkupvm} / ${carbs[0].loppupvm}`
        : "",

    history: joku ? joku : [],
  };}
function Row(props) {    
  const [openSopimus, setopenSopimus] = React.useState(false);
  const [valittussopimus, setValittusopimus] = React.useState(null);  
  const { row, dialog, valittu } = props;
  const [open, setOpen] = React.useState(false);
  const [aktiivinen, setAktiivinen] = React.useState(false);

  const handleChange = (event) => {
    setAktiivinen(!aktiivinen);
  };
  
  const handleTableClose = () => {
    // setisActive(false);
    // setActiveC(true);
    dialog(true);

    valittu(row);
  };

  const handleSopimusOpen = (sopimus) => {
    console.log(sopimus);
    setValittusopimus(sopimus);
    setopenSopimus(true);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          ...(open && { backgroundColor: "lightgreen" }),
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Tooltip title="Uusi sopimus">
            <Button>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="add"
              >
                <AddIcon onClick={handleTableClose} key={row.sijainen_id} />
              </Fab>
            </Button>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title="Näytä sopimus">
            <Button>

                <Avatar onClick={() => handleSopimusOpen(row)} style={{padding: "10px"}}sx={{bgcolor: pink[500]}}><PageviewIcon /></Avatar>
            </Button>
          </Tooltip>
        </TableCell>        
        <TableCell scope="row">
          {row.name}
        </TableCell>
        <TableCell scope="row">
          {row.vastuualue_lyh}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell scope="row">
          {row.info}
        </TableCell>        
        <TableCell align="right">
          <FormControlLabel
            control={<Switch onChange={handleChange} />}
            label={aktiivinen ? "listalla" : "poissa"}
            key={row.id} // sijaisen_id. Tähän event joka päivittää sijaisen saatavuuden
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Historia
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Jakso</TableCell>
                    <TableCell>Osasto</TableCell>
                    <TableCell align="right">Vakanssinumero</TableCell>
                    <TableCell align="right">Lisäinfo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">{historyRow.sopimus}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function CollapsibleTable() {
  const [openSopimus, setopenSopimus] = React.useState(false);
  const Sopimukset = useQuery(HaeSopimukset);
  const sopimukset_data = Sopimukset?.data?.Sopimukset ?? []
  const [valittussopimus, setValittusopimus] = React.useState(null);  

  const freesaa = () => {
    Sopimukset.refetch();
  };
  const [sijainenvalittu, setSijainenvalittu] = React.useState(null);
  const mappa = () => {
    const uusi = Sopimukset.data?.SijaistenSopimukset?.map((sijainen) => {
      const { sijainen_id, etunimi, sukunimi, puhelin, sopimus } = sijainen;
      return createData(
        sijainen_id,
        etunimi + " " + sukunimi,
        puhelin,
        sopimus,
      );
    });
    return typeof uusi === "undefined" ? [] : uusi;
  };

  const renderDialog = () => {
    if (openSopimus) {
      return (
        <SopimusTiedot
          kalle={() => setopenSopimus(true)}
          close={() => setopenSopimus(false)}
          sopimus={valittussopimus}
        />
      );
    }
  }

  if (openSopimus) {
    return (
      <FullSreenD
        kalle={() => setopenSopimus(true)}
        close={() => setopenSopimus(false)}
        paivita={freesaa}
        sissi={sijainenvalittu}
      />
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>Sijainen</TableCell>
            <TableCell align="right">Osasto</TableCell>
            <TableCell align="right">Puhelinnumero</TableCell>

            <TableCell align="right">Nykyinen sopimus</TableCell>
            <TableCell align="right">Lisätieto</TableCell>
            <TableCell align="right">Aktiivinen</TableCell>
          </TableRow>
        </TableHead>
        {renderDialog()}
        <TableBody>
          {mappa().map((sijainen) => (
            <Row
              key={sijainen.sijainen_id}
              row={sijainen}
              dialog={setopenSopimus}
              valittu={setSijainenvalittu}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
