import { gql } from "@apollo/client";

export const Taustat = gql`
  query Taustat {
    Taustat {
      tausta_id
      kokonimi
    }
  }
`;
