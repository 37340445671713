
import React from 'react'
import { Input, Box } from '@mui/material';

export const GlobalFilter = ({filter,setFilter}) =>{
    return (
        <Box sx={{width: "100%; background: #04AA6D; color: white;"}} display="flex">
            <Input placeholder="Hae..." sx={{border: "1px solid green; background: #fff"}} disableUnderline label='Haku' variant="Haku" value={filter || ''} onChange={e=>setFilter(e.target.value)}/>
        </Box>
    )
}