import React, {Fragment}  from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MiscellaneousServicesIcon from '@material-ui/icons/AccessTime';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
//import MiscellaneousServicesIcon from '@material-ui/icons/MiscellaneousServicesIcon';
//import BuildIcon from '@material-ui/icons/BuildIcon';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NotificationsIcon from "@material-ui/icons/Notifications";

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Button, Container, Grid, Icon, Tooltip } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PersonIcon from '@material-ui/icons/Person';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons//Navigation';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import System from './System';
import TabPanel from './TabPanel';
import Menu from './Menu';
import App from './App';
import EmpDialog from './EmpDialog'
import Details from './Details'
import {
  BrowserRouter as Router,
    Routes,
    Route,
  Link
} from "react-router-dom";

//import {Switch } from "react-router-dom"



import { AccessAlarm, ThreeDRotation, Build} from '@material-ui/icons';
const Demo = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
}));
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const drawerWidth = 240;
const numbers = [1, 2, 3, 4, 5];
//const doubled = numbers.map(number);
const menu = [
	<Build style={{ fill: "green" }} />,
	<AccessTimeIcon />,
	<AccessAlarm />,
	<ThreeDRotation style={{ fill: "red" }}/>,
];
const polut = [
  "Hallinta",
  "EmpDialog",
  "Info",
  "Raportti",
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
function Clock(props) {
  return (
    <div id="kello">
      
      <h2>{props.date.toLocaleTimeString()}</h2>
    </div>
  );
}


export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openNew, setOpenNew] = React.useState(false);
 
const handleCloseNew = () => {
		setOpenNew(false);
	};

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSystemOpen =() =>
  {
     setOpen(true);
  
  };
  const handleSystemClose =() =>
  {
     setOpen(false);
  
  };
  const handleClickOpenNew = () => {
		setOpenNew(true);
	};
  function tick() {
    
    ReactDOM.render(
      <Clock date={new Date()} />,
      document.getElementById('kello')
    );
  setInterval(tick, 1000);
   
  }

  return (
     
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Button  color="inherit">
            JÄRJESTELMÄNHALLINTA
            
             </Button>
             <Button color="inherit">ADMIN</Button>
          <Box sx={{ flexGrow: 1 }} />
          <Clock date={new Date()} />
          <Badge badgeContent={3} color="secondary">
                <MailIcon/>
              </Badge>

              <IconButton
						aria-label="show 17 new notifications"
						color="inherit"
						onClick={handleClickOpenNew}
					>
						<Badge badgeContent={17} color="secondary">
							<NotificationsIcon />
						</Badge>
					</IconButton>

        </Toolbar>
      </AppBar>
       
       
      <Drawer
        className={classes.drawer}
        sx={{
          width: 500,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 500,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"      
        open={open}
        classes={{
          paper: classes.drawerPaper,
         
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <Router>
        <List>
          
          {['Järjestelmänhallinta', 'Resurssihallinta','Herätteet','Raportti'].map((text, index) => (
           <Link to={polut[index]}> <ListItem button key={text} onClick={showSystem(index)}>
              <ListItemIcon>{menu[index]}</ListItemIcon>
             
              <ListItemText primary={text} />
            </ListItem>
           </Link >
            
          ))}
          
         

        </List>
        <Routes>
        <Route exact path="/" render={() => <div>Home Page</div>} />
        <Route exact path="/EmpDialog" element={<EmpDialog/>}>
        
        </Route>
        </Routes>
        </Router>
        <Divider />
        
        <List>
        
        <ListItem><ListItemText primary="SAVPE"/></ListItem>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
            
          ))}
          
        </List>
        
      </Drawer>
      
      >
      
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        
      </main>
      <div>
				<Dialog
					open={openNew}
					onClose={handleCloseNew}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-New">
						{"Huomiot ja heräteet"}
						<Divider />
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-new">
							<box>{}</box>
						</DialogContentText>
						<Demo>
							<List>
								{["Sijaisuus keskeneräinen", " Uusia sijaisuuspyyntöjä", "Muu toiminto","Seuraava toiminto"].map(
									(text, index) => (
										<ListItem button key={text}>
											<ListItemIcon>{menu[index]}</ListItemIcon>

											<ListItemText secondary={text} />
										</ListItem>
									)
								)}
							</List>
						</Demo>
					</DialogContent>
					<DialogActions>
						
						<Button onClick={handleCloseNew} autoFocus>
							Sulje
						</Button>
					</DialogActions>
				</Dialog>
			</div>

      
    </div>
    
    
  );
  
 
}



function showSystem(props){
console.log("toimm");
return(
<Details />
);
  
}