import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
//import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
//import ConstructionIcon from "@material-ui/icons/Construction";
//import HandymanIcon from "@material-ui/icons/Handyman";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { AccessAlarm, ThreeDRotation, Build } from "@material-ui/icons";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import HomeIcon from "@material-ui/icons/Home";
import TabPanel from "./TabPanel";
import EmpDialog from "./EmpDialog";
import Info from "./Info";
import { StepsProvider } from "./Context";
import ResurssiKiinnitys from "./ResurssiKiinnitys";
import SijaisResurssi from "./sijaisresurssi2";
import Resource from "./Resource";
import Etusivu from "./Etusivu";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { SnackProvider } from "./contexts/SnackContext";

const drawerWidth = 240;

const menu = [
  <HomeIcon style={{ fill: "green" }} />,
  <AccessTimeIcon />,
  <AdminPanelSettingsIcon />,
  <AccessAlarm />,
  <TimelapseIcon style={{ fill: "red" }} />,
];

const user_menu = ["Ekfkfkf", "ttttoopp"];
const admin_menu = ["Etusivu", "ylllto"];
//const admi_json = { osaaminen_id: 1, suoritetyyppi: , c: 3 };

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);

  console.log("Open", openSnack);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (props) => (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {props.menuItems.map((text, index) => (
          <ListItem key={text} component={Link} to={"/" + text}>
            <ListItemIcon>{menu[index]}</ListItemIcon>
            <ListItemText secondary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <SnackProvider>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              TradeApp v0.1.8
            </Typography>
          </Toolbar>
        </AppBar>
        <Router>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer(props)}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer(props)}
              </Drawer>
            </Hidden>
          </nav>

          <main className={classes.content}>
            <div className={classes.toolbar} />

            <Routes>
              <Route exact path="/" />
              <Route exact path="/etusivu" element={<Etusivu />} />
              <Route path="/resurssitilaus" element={<Resource />} />
              <Route
                path="/Resurssikiinnitykset"
                element={<SijaisResurssi />}
              />
              <Route path="/Adminhallinta" element={<EmpDialog />} />
            </Routes>
          </main>
        </Router>
      </SnackProvider>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  ),
};

export default ResponsiveDrawer;
