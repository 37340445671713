import { gql } from "@apollo/client";

export const Nimikeryhmat = gql`
	query Nimikeryhmat {
		Nimikeryhmat {
			nimikeryhma_id
			lyhenne
			lisatieto
			kokonimi
        }
	}
`;