import { gql } from "@apollo/client";

export const Sijaiset = gql`
  query Sijaiset {
    Sijaiset {
      sijainen_id
      etunimi
      sukunimi
      puhelin
      sahkoposti
      lahiosoite
      postinumero
      postitoimipaikka
      lisatieto
      sijainen_nimike {
        nimike {
          nimike_id
          lyhenne
        }
      }
      sijainen_osaamiskartta {
        osaamiskartta_id
      }
    }
  }
`;

export const TallennaSijainen = gql`
  mutation TallennaSijainen(
    $sijainen_id: Int
    $etunimi: String!
    $sukunimi: String!
    $puhelin: String!
    $sahkoposti: String!
    $lahiosoite: String!
    $postinumero: String!
    $postitoimipaikka: String!
    $nimikkeet: [Int]!
    $osaamiskartta: [Int]!
  ) {
    TallennaSijainen(
      sijainen_id: $sijainen_id
      etunimi: $etunimi
      sukunimi: $sukunimi
      puhelin: $puhelin
      sahkoposti: $sahkoposti
      lahiosoite: $lahiosoite
      postinumero: $postinumero
      postitoimipaikka: $postitoimipaikka
      nimikkeet: $nimikkeet
      osaamiskartta: $osaamiskartta
    ) {
      etunimi
      sukunimi
      sahkoposti
      puhelin
      lahiosoite
      postinumero
      postitoimipaikka
      sijainen_nimike {
        nimike_id
      }
      sijainen_osaamiskartta {
        osaamiskartta_id
      }
    }
  }
`;

export const PoistaSijainen = gql`
  mutation PoistaSijainen($sijainen_id: Int!) {
    PoistaSijainen(sijainen_id: $sijainen_id) {
      sijainen_id
    }
  }
`;
