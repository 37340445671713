import React, { useState, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
  useMemo,
  Label,
  Grid,
} from "react-table";
//import { useTheme } from '@table-library/react-table-library/theme';
import MOCK_DATA from "./MOCK_DATA.json";
import { COLUMNS, product } from "./columns2";
import { date } from "date-fns/locale";
import { Button, Box } from "@mui/material";
import "./table.css";
import { GlobalFilter } from "./GlobalFilter";
import SopimusTiedot from "./components/SopimusTiedot";
import { useQuery, useMutation } from "@apollo/client";
import { HaeSopimkset } from "./services/SopimusService";
import { ArkistoiSopimus } from "./services/TallennaSopimusService";
import { ConstructionRounded, Event } from "@mui/icons-material";
import PageviewIcon from "@mui/icons-material/Pageview";
import Fab from '@material-ui/core/Fab';
import { SnackContext } from "./contexts/SnackContext";

export default function SijaisResurssi() {
  const [sopimukset, setSopimukset] = useState([]);
  const [openSopimus, setopenSopimus] = React.useState(false);
  const [valittussopimus, setValittusopimus] = React.useState(null);
  const snack = React.useContext(SnackContext);
  const [arkistoisopimus, setArkistoisopimus] = useMutation(
    ArkistoiSopimus,
    { refetchQueries: [HaeSopimkset] }
  );

  const Sopimukset = useQuery(HaeSopimkset);
  const sopimukset_data = Sopimukset?.data?.Sopimukset ?? [];

  const handleSopimusOpen = (sopimus) => {
    setValittusopimus(sopimus);
    setopenSopimus(true);
  };

  const handleTila = (sopimus, checked) => {
    console.log(sopimus.sopimus_id);
    (checked) ? setSopimukset([...sopimukset, sopimus.sopimus_id]) : sopimukset.splice(sopimukset.indexOf(sopimus.sopimus_id), 1);
    console.log(sopimukset);
  };

  const tallennaArkistoon = () => {
    //const uniqueNames = Array.from(new Set(sopimukset));
    if (sopimukset.length == 0) {
      snack.setSnack("Valitse arkistoitavat sopimukset!");
      return;
    }
    sopimukset.forEach((sopimus_id) => {
      console.log(sopimus_id);
      //let clonedSopimus = {...sopimukset}
      //clonedSopimus = {...clonedSopimus, arkistoitu: true}
      arkistoisopimus({ variables: {sopimus_id: sopimus_id, arkistoitu: true }});
      //console.log(clonedSopimus);
    });
    snack.setSnack("Sopimukset arkistoitu!");
    //this.state.sopimukset = [];
    this.setState({sopimukset: []});
    //console.log(uniqueNames);
  };

  const renderDialog = () => {
    if (openSopimus) {
      return (
        <SopimusTiedot
          kalle={() => setopenSopimus(true)}
          close={() => setopenSopimus(false)}
          sopimus={valittussopimus}
        />
      );
    }
  };

  const sijaisetInstance = useTable(
    {
      columns: COLUMNS,
      data: sopimukset_data,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    getRowProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    id,
    setGlobalFilter,
  } = sijaisetInstance;

  const { pageIndex } = state;
  const { globalFilter } = state;

  if (Sopimukset.data === null) return "Ladataan...";

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "left", alignItems: "left" }}>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </Box>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span><br/>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                    {column.id === "arkisto" ? (
                      <Button variant="outlined" onClick={() => tallennaArkistoon()}>Arkistoi</Button>
                    ) : (
                      console.log("ssd")
                    )}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {renderDialog()}
        <tbody {...getTableBodyProps()}>
          {page?.map((row) => {
            prepareRow(row);
            return (
              <tr {...row?.getRowProps()}>
                {row?.cells?.map((cell, j) => {
                  switch (j) {
                    case 0: {
                      return (
                        <td
                          onClick={() => handleSopimusOpen(row.original)}
                          style={{ padding: "10px" }}
                          {...cell?.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                    case 5: {
                      return (
                        <td
                          onClick={(e) => handleTila(row.original, e.target.checked)}
                          style={{ padding: "10px" }}
                          {...cell?.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                    default: {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    }
                  }
                })}
                )
              </tr>
            );
          })}
        </tbody>
      </table>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <span>
          Sivu{" "}
          <strong>
            {pageIndex + 1} / {pageOptions.length}
          </strong>
        </span>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          Ensimmäinen
        </Button>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Edellinen
        </Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Seuraava
        </Button>
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          Viimeinen
        </Button>
      </Box>
    </>
  );
}
