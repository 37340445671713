import React, { useState, createContext, useContext } from "react";
import SwipeableViews from "react-swipeable-views";
import { Paper, Grid, Typography, withStyles, Button } from "@material-ui/core";
import WizardHeader from "./wizardHeader";
import RadioMasters from "./radioMasters";
import SelectService from "./selectService";
import SelectDateDaypart from "./selectDateDaypart";
import Contacts from "./contacts";
import RecipeReviewCard from "./toimialue";
import KurssiCard from "../KurssiCard";
import Yhteenveto from "./Yhteenveto";
import CustomInput from "./CustomInput";
import { height } from "@mui/system";
import { useMutation } from "@apollo/client";
import { TallennaResurssitilaus } from "../services/ResurssitilausService";
import { SnackContext } from "../contexts/SnackContext";
import LetterAvatars from "../kaikkisijaiset";

export const UserContext = createContext();

const style = (theme) => ({
  root: {
    border: `8px solid ${theme.palette.common.white}`,
    margin: 16,
    padding: "36px",
    background: `rgba(255,255,255,0.9)`,
    boxShadow: [
      `0px 16px 26px -10px ${theme.palette.primary.main}99`,
      theme.shadows[15],
    ],
  },
  navigation: {
    width: 100,
    fontSize: 12,
    [theme.breakpoints.down("lg")]: {
      fontSize: 12,
      width: 90,
    },
  },
  prevBtn: {
    color: theme.palette.grey[700],
    background: theme.palette.common.white,
    boxShadow: theme.shadows[5],
  },
});

const Content = ({ classes }) => {
  const snack = React.useContext(SnackContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [tallennaResurssitilaus, tallennaResurssitilausResult] = useMutation(
    TallennaResurssitilaus,
    {
      onCompleted: () => {
        snack.setSnack("Resurssitilaus tallenettu");
      },
    }
  );
  const [activeStep, setActiveStep] = useState(
    localStorage.getItem("step") ? Number(localStorage.getItem("step")) : 0
  );
  const handleChange = (index) => (e) => {
    setActiveStep(index);
    localStorage.setItem("step", index);
  };
  const nandleNext = () => {
    setActiveStep(activeStep + 1);
    localStorage.setItem("step", activeStep + 1);
  };
  const nandlePrev = () => {
    setActiveStep(activeStep - 1);
    localStorage.setItem("step", activeStep - 1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.clear();
    setFormSubmitted(true);
    const data = Array.from(e.target.elements)
      .map((el) => el.id)
      .filter(Boolean)
      .reduce((accObj, field) => {
        accObj[field] = e.target.elements[field].value;
        return accObj;
      }, {});
    //alert(JSON.stringify(data, null, 1));
    console.log(resurssiTilausTiedot);

    const {
      osasto_id,
      aloituspvm,
      lopetuspvm,
      sijainen_id,
      tilaaja,
      lisatieto,
      tilaaja_yhteystieto,
      vakanssinumero,
      vyks,
      tausta_id,
      toimialue_id,
      palvelualue_id,
      vastuualue_id,
      osaamiskartta,
    } = resurssiTilausTiedot;

    if (
      aloituspvm === null ||
      lopetuspvm === null ||
      tilaaja === null ||
      vakanssinumero === null ||
      vyks === null ||
      tausta_id === null ||
      toimialue_id === null ||
      palvelualue_id === null ||
      vastuualue_id === null
    ) {
      alert("Puuttelliset tiedot");
      return;
    }

    tallennaResurssitilaus({
      variables: {
        aloituspvm,
        lopetuspvm,
        tilaaja,
        lisatieto,
        tilaajayhteystieto: tilaaja_yhteystieto,
        vakanssinumero,
        vyks,
        tausta_id,
        toimialue_id,
        palvelualue_id,
        vastuualue_id,
        osaamiskartta, //: osaamiskartta.map((id) => {
        //   return { osaamiskartta_id: id };
        // }),
      },
    });
  };
  const [resurssiTilausTiedot, setResurssiTilausTiedot] = useState({
    aloituspvm: null,
    lopetuspvm: null,
    tilaaja: null,
    lisatieto: null,
    tilaaja_yhteystieto: null,
    vakanssinumero: null,
    vyks: null,
    tausta_id: null,
    toimialue_id: null,
    palvelualue_id: null,
    vastuualue_id: null,
    osaamiskartta: null,
  });

  const tabs = ["Toimialue", "Osaamiskartta", "Ajanjakso", "Tilaaja", "Tilaa"];

  if (tallennaResurssitilausResult.loading) {
    return <p>Tallennetaan...</p>;
  }

  if (tallennaResurssitilausResult.error) {
    console.log(tallennaResurssitilausResult.error.networkError);
    return <p>Virhe tallennuksessa</p>;
  }

  return (
    <div style={{}}>
      <Paper
        style={{ width: "800px height: 2000px" }}
        elevation={1}
        className={classes.root}
      >
        <Typography
          variant="h4"
          gutterBottom
          color="primary"
          style={{ padding: "0 8px" }}
        >
          {resurssiTilausTiedot.jokutieto}
          {resurssiTilausTiedot.muutieto}
        </Typography>
        <Typography gutterBottom>
          Syötä tarvittavat tiedot resurssin tilaamiseksi. Paina lopuksi 'tilaa'
        </Typography>
        <WizardHeader
          tabs={tabs}
          activeStep={activeStep}
          handleChange={handleChange}
          formSubmitted={formSubmitted}
        />
        <UserContext.Provider
          value={{
            tiedot: resurssiTilausTiedot,
            callback: setResurssiTilausTiedot,
          }}
        >
          <form onSubmit={handleSubmit}>
            {
              <SwipeableViews index={activeStep} onChangeIndex={handleChange}>
                <KurssiCard />

                <SelectService />
                <SelectDateDaypart />
                <Contacts formSubmitted={formSubmitted} />
                <Yhteenveto />
              </SwipeableViews>
            }
            <Grid
              container
              justifyContent="space-between"
              style={{ padding: "16px 16px" }}
            >
              <Grid item>
                <Button
                  disabled={activeStep === 0 || formSubmitted}
                  onClick={nandlePrev}
                  variant="contained"
                  color="default"
                  className={`${classes.navigation} ${classes.prevBtn}`}
                >
                  Edellinen
                </Button>
              </Grid>
              {activeStep < tabs.length - 1 && (
                <Grid item>
                  <Button
                    color="primary"
                    className={classes.navigation}
                    variant="contained"
                    onClick={nandleNext}
                    disabled={formSubmitted}
                  >
                    Seuraava
                  </Button>
                </Grid>
              )}
              {activeStep === tabs.length - 1 && (
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    className={classes.navigation}
                    variant="contained"
                    //disabled={formSubmitted}
                  >
                    Tilaa
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </UserContext.Provider>
      </Paper>
    </div>
  );
};
export default withStyles(style)(Content);
