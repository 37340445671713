import { gql } from "@apollo/client";

export const Toimialueet = gql`
	query Toimialueet {
		Toimialueet {
			toimialue_id
			kokonimi
			lyhenne
			lisatieto
			vastaava
		}
	}
`;
