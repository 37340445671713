
import React from 'react';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';

import { makeStyles } from '@material-ui/core/styles'

class EmployeeTable extends React.PureComponent {
 render(){
   return(
   <div>
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650, color: 'red' }} aria-label="simple table">
        <TableHead sx={{bgcolor: 'blue',
        
      }}>
          <TableRow>
             <TableCell align="right" style={{backgroundColor:'#81d4fa', color: 'gray',}}>ID_Emp</TableCell>
             <TableCell align="right" style={{backgroundColor:'lightgray', color: 'gray',}}>Sijaisnumero</TableCell>
             <TableCell sx={{background: '#d81b60'}}>Etunimi</TableCell>
            <TableCell sx={{background: '#d81b60'}}>Sukunimi</TableCell>
            <TableCell align="right">Kurssilyhenne</TableCell>
            <TableCell align="right">Puhelinnumero</TableCell>
            <TableCell align="right">Sähköposti</TableCell>
            <TableCell align="right">Lähiosoite</TableCell>
             <TableCell align="right">Postinumero</TableCell>
            </TableRow>
        </TableHead>
        
      </Table>
    </TableContainer>
    </div>
   )
 }
}

export default EmployeeTable