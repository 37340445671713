import logo from './logo.svg';
import ReactDOM from 'react-dom';
import './App.css';
import { Button, Container, Grid, Icon } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save'
import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
//import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
//import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core//MenuItem';
import { styled, alpha } from '@material-ui/core/styles';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
//import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Stack from '@material-ui/core/StepIcon';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Info from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import MuiAlert from '@material-ui/MuiAlert';
//import {BrowserRouter as Router,Route,Link,Switch} from 'react-router-dom';
//import Form from './Form';

//import Tutorials from './Menu.js';
import { render } from '@testing-library/react';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import { AccessAlarm, ThreeDRotation, Build} from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InboxIcon from '@material-ui/icons/MoveToInbox';





interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const drawerWidth = 240;
const numbers = [1, 2, 3, 4, 5];
//const doubled = numbers.map(number);
const menu =[<Build/>,<AccessTimeIcon/>,<AccessAlarm/>,<ThreeDRotation/>];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Home = () => (
  <div> <h2>TKTL notes app</h2> </div>
)

const Notes = () => (
  <div> <h2>Notes</h2> </div>
)

const Users = () => (
  <div> <h2>Users</h2> </div>
)

const henkilot=[0,1,2,3,4,5,6,7,8,9,];
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
function Clock(props) {
  return (
    <div id="kello">
      
      <h2>{props.date.toLocaleTimeString()}</h2>
    </div>
  );
}

  function onSubmit()  {

    alert("joo");
       
    render()
    {
      return(
        <div/>
      );
      
     
    }
          
        
      
          //return  <Redirect  to="./Form/" />
       
    }  
        

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function ButtonAppBar() {
  const [open, setOpen] = React.useState(false);
  const [openS, setOpenS] = React.useState(true);
  const [openNew, setOpenNew] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true);
    
  };

  const handleClickOpenNext = () => {
    setOpenS(true);
  };
 const handleClick = (event: React.MouseEvent<HTMLElement>, text: string, id:number) => {
  console.log(event.target);
  console.log(text);
  console.log(id);
  alert(text);
};
  const handleClickOpenNew = () => {
    setOpenNew(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseNew = () => {
    setOpenNew(false);
  };
  function tick() {
    
    ReactDOM.render(
      <Clock date={new Date()} />,
      document.getElementById('kello')
    );
      
   
  }

  
  setInterval(tick, 1000);

  return (
    <Box sx={{ flexGrow: 3 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon  />
          </IconButton>
          <Button color="inherit">Tiedotteita</Button>
          
          <Button color="inherit">ADMIN</Button>
          <Box sx={{ flexGrow: 1 }} />
          <Clock date={new Date()} />
          <Badge badgeContent={3} color="secondary">
                <MailIcon/>
              </Badge>
         
       <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
        </Toolbar>
      </AppBar>
      
      <Box m={2} pt={3}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          
          <Item >
            <Typography color="#0000FF"> 
               Salla Sairaanhoitaja
            </Typography>
            <Box color="#0000FF">* Osasto 8, 1.9.2021 - 31.5.2022</Box>
            <Button sx="0" color="secondary" onClick={onSubmit}>HUOMIO
              <Info />
              </Button>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item onClick={handleClickOpen}><Typography color="#0000FF"> 
               Eino Ensihoitaja
            </Typography><Box color="#FF0000">* ENSI I, 1.3.2021 - 22.8.2021</Box> </Item>
        </Grid>
        <Grid item xs={4}>
          <Item onClick={handleClickOpenNew}><Typography color="#0000FF"> 
               Hanna Hammashoitaja
            </Typography><Box color="#0000FF">* Osasto 8, 1.9.2021 - 31.5.2022</Box></Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Typography color="#0000FF"> 
               Salla Sairaanhoitaja
            </Typography>
            <Box color="#0000FF">* Osasto 8, 1.9.2021 - 31.5.2022</Box>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item><Typography color="#0000FF"> 
               Eino Ensihoitaja
            </Typography><Box color="#FF0000">* ENSI I, 1.3.2021 - 22.8.2021</Box> </Item>
        </Grid>
        <Grid item xs={4}>
          <Item><Typography color="#0000FF"> 
               Hanna Hammashoitaja
            </Typography><Box color="#0000FF">* Osasto 8, 1.9.2021 - 31.5.2022</Box></Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Typography color="#0000FF"> 
               Salla Sairaanhoitaja
            </Typography>
            <Box color="#0000FF">* Osasto 8, 1.9.2021 - 31.5.2022</Box>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item><Typography color="#0000FF"> 
               Eino Ensihoitaja
            </Typography><Box color="#FF0000">* ENSI I, 1.3.2021 - 22.8.2021</Box> </Item>
        </Grid>
        <Grid item xs={4}>
          <Item><Typography color="#0000FF"> 
               Hanna Hammashoitaja
            </Typography><Box color="#0000FF">* Osasto 8, 1.9.2021 - 31.5.2022</Box></Item>
        </Grid>
        <Grid item xs={4} itemID="0">
          <Item itemID="1" id="S">
            <Typography color="#0000FF"> 
               Salla Sairaanhoitaja
            </Typography>
            <Box color="#0000FF">* Osasto 8, 1.9.2021 - 31.5.2022</Box>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item id="E">
            <Typography color="#0000FF"> 
               Eino Ensihoitaja
            </Typography><Box color="#FF0000">* ENSI I, 1.3.2021 - 22.8.2021</Box> </Item>
        </Grid>
        <Grid item xs={4}>
          <Item><Typography color="#0000FF"> 
               Hanna Hammashoitaja
            </Typography><Box color="#0000FF">* Osasto 8, 1.9.2021 - 31.5.2022</Box></Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Typography color="#0000FF"> 
               Salla Sairaanhoitaja
            </Typography>
            <Box color="#0000FF">* Osasto 8, 1.9.2021 - 31.5.2022</Box>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item><Typography color="#0000FF"> 
               Eino Ensihoitaja
            </Typography><Box color="#FF0000">* ENSI I, 1.3.2021 - 22.8.2021</Box> </Item>
        </Grid>
        <Grid item xs={4}>
          <Item><Typography color="#0000FF"> 
               Hanna Hammashoitaja
            </Typography><Box color="#0000FF">* Osasto 8, 1.9.2021 - 31.5.2022</Box></Item>
        </Grid>
        <Grid item xs={4}>
          <Item>xs=8</Item>
        </Grid>

      </Grid>
       
      </Box>
      
      <div>
      
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar><DialogTitle id="alert-dialog-title">
          {"Eino Ensihoitaja"}
        </DialogTitle></AppBar>
        <DialogContent>
      
      <FormControl component="fieldset">
      <FormLabel component="legend"><Typography color="blue"> Lääkelupa</Typography></FormLabel>
      <RadioGroup row aria-label="IV-" name="row-radio-buttons-group">
        <FormControlLabel value="IV+" control={<Radio />} label="IV -" />
        <FormControlLabel value="IV?" control={<Radio />} label="IV +" />
        <FormControlLabel value="Nesteytys" control={<Radio />} label="IV ?" />
        <FormControlLabel
          value="Nesteytys"
          disabled
          control={<Radio />}
          label="Muuta?"
        />
      </RadioGroup>
      <Divider /><Box flex />
      <div>
      <FormLabel component="kurssit">Suoritetut kurssit</FormLabel>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box>
     <FormControlLabel
          value="k1"
          control={<Checkbox />}
          label="Työturvallisuuskurssi"
          labelPlacement="top"
        />
      <FormControlLabel
          value="k2"
          control={<Checkbox />}
          label="Lääketurvallisuus kurssi"
          labelPlacement="top"
        />
      <FormControlLabel
          value="bottom"
          control={<Checkbox />}
          label="Tulityökurssi"
          labelPlacement="top"
        />
      </Box>
      </Box>
      

     
     </div>
     <Divider />
    </FormControl>
          <DialogContentText id="alert-dialog-description">
           <Box>Sijaisuustausta: </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Sulje</Button>
          <Button onClick={handleClose} >
            Tallenna
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    <div>
      
      <Dialog
        open={openNew}
        onClose={handleCloseNew}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-New">
          {"Hanna Hammashoitaja"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-new">
           <box>Sijaisuustausta: {}</box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNew}>Muokkaa</Button>
          <Button onClick={handleCloseNew} autoFocus>
            Sulje
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </Box>
 );
  function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
 

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSystemOpen =() =>
  {
     setOpen(true);
  
  };
  const handleSystemClose =() =>
  {
     setOpen(false);
  
  };
  

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            SAVPE
            
             </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        sx={{
          width: 500,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 500,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"      
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {['Järjestelmänhallinta', 'Resurssihallinta','Raportti'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{menu[index]}</ListItemIcon>
             
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
        <ListItem><ListItemText primary="SAVPE"/></ListItem>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
          facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
          gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id
          donec ultrices. Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
          adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra nibh cras.
          Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo quis
          imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget
          arcu dictum varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem
          donec massa sapien faucibus et molestie ac.
        </Typography>
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper eget nulla
          facilisi etiam dignissim diam. Pulvinar elementum integer enim neque volutpat ac
          tincidunt. Ornare suspendisse sed nisi lacus sed viverra tellus. Purus sit amet volutpat
          consequat mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis risus sed
          vulputate odio. Morbi tincidunt ornare massa eget egestas purus viverra accumsan in. In
          hendrerit gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem et
          tortor. Habitant morbi tristique senectus et. Adipiscing elit duis tristique sollicitudin
          nibh sit. Ornare aenean euismod elementum nisi quis eleifend. Commodo viverra maecenas
          accumsan lacus vel facilisis. Nulla posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography>
      </main>
    </div>
  );
}

  
}

 
