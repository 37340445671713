import { ColumnFilter } from "./ColumnFilter";import { CFilter } from "./ColumnFilter";
import { RowFilter } from "./RowFilter";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Avatar from "@mui/material/Avatar";
import PageviewIcon from "@mui/icons-material/Pageview";
import { green, pink, red } from "@mui/material/colors";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Box from "@material-ui/core/Box";
import { Divider } from "@material-ui/core";
import { AccordionSummary } from '@mui/material';
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { format } from "date-fns";
import { fil } from "date-fns/locale";

const handleAlert = (e) => {
  const elem = document.getElementById("checkbox");
  alert(elem.value);
};
const product = [
  {
    _id: "1",
    name: "Shoes",
    images: "./img.shoe4.jfif",
    discription: "Lorem10j,jdscjscjc nxkd kdn ddksad asdkjdhsadb",
    brand: "Apple",
    category: "Electronics",
    price: 89.3,
    countInStocks: 3,
    rating: 4.5,
    numReviews: 4,
  },
];
export const COLUMNS = [
  {
    Header: "Info",
    accessor: "",
    Cell: (row) => {
      return (
        <Avatar sx={{ bgcolor: pink[500] }}>
          <PageviewIcon />
        </Avatar>
      );
    },
    Filter: ColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Yksikkö",
    accessor: (row) => row.vastuualue.lyhenne,
    Filter: ColumnFilter,
    disableFilters: false,
  },
  {
    Header: "Vakanssinumero",
    accessor: "vakanssinumero",
    Filter: ColumnFilter,
    disableFilters: false,
  },
  {
    Header: "Tilattu ajanjakso",
    accessor: (row) => row.alkupvm + " - " + row.loppupvm,
    Filter: ColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Tilauspäivä",
    accessor: (row) => row.resurssitilaus.map((arvo) => arvo.aikaleima),
    //Cell: (value) => { return format(new Date(value), 'dd/MM/YYYY')},
    Filter: ColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Arkistoi tilaus",
    accessor: "arkisto",
    id: "arkisto",
    Cell: (row) => {
      return (
        <FormControlLabel
          control={
            <Checkbox
              id={row.row.original.sopimus_id}
              /*onClick={() => handleAlert(row.row.original.sopimus_id)}*/
            />
          }
          label="Arkistoi"
        />
      );
    },
    Filter: ColumnFilter,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Aktiivinen",
    accessor: "sopimus_tehtava",
    Cell: (row) => {
      const tehtavarivit = row?.value?.map((rivi) => rivi?.valmis);
      const valmis = tehtavarivit?.every((rivivalmis) => rivivalmis);
      return (
        <Button>
          <Avatar sx={{ bgcolor: valmis ? green[500] : red[500] }}>
            {valmis ? <DoneOutlineIcon /> : <PriorityHighIcon />}
          </Avatar>
        </Button>
      );
    },
    Filter: ({ filter, onChange,row, rows }) => {
      return (
        <Checkbox
          onChange={(event) => {
            console.log(row.row.original.valmis);
            //console.log(event.target.checked);
          }}
        ></Checkbox>
      );
    },
    disableSortBy: true,
  },
];
