import { gql } from "@apollo/client";

export const LoginPalvelu = gql`
	query TarkistaKayttaja($kayttajatunnus: String!, $salasana: String!) {
		TarkistaKayttaja(kayttajatunnus: $kayttajatunnus, salasana: $salasana) {
			taso
			kayttaja_id
		}
	}
`;
