import { gql } from "@apollo/client";

export const TallennaResurssitilaus = gql`
  mutation TallennaResurssitilaus(
    $resurssitilaus_id: Int
    $aloituspvm: Date!
    $lopetuspvm: Date!
    $tilaaja: String!
    $lisatieto: String
    $tilaajayhteystieto: String
    $vakanssinumero: String!
    $vyks: String!
    $tausta_id: Int!
    $toimialue_id: Int!
    $palvelualue_id: Int!
    $vastuualue_id: Int!
    $osaamiskartta: [Int]!
  ) {
    TallennaResurssitilaus(
      resurssitilaus_id: $resurssitilaus_id

      aloituspvm: $aloituspvm
      lopetuspvm: $lopetuspvm

      tilaaja: $tilaaja
      lisatieto: $lisatieto
      tilaajayhteystieto: $tilaajayhteystieto
      vakanssinumero: $vakanssinumero
      vyks: $vyks
      tausta_id: $tausta_id
      toimialue_id: $toimialue_id
      palvelualue_id: $palvelualue_id
      vastuualue_id: $vastuualue_id
      osaamiskartta: $osaamiskartta
    ) {
      resurssitilaus_id
    }
  }
`;
export const HaeTilaukset = gql`
  query Kaikkitilaukset {
    AvoimetTilaukset {
      resurssitilaus_id
      aloituspvm
      lopetuspvm
      tilaajayhteystieto
      vakanssinumero
      vyks
      vastuualue {
        kokonimi
      }
    }
  }
`;
