import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { withStyles, FormControlLabel, Grid, Switch } from "@material-ui/core";
import { curl, straight, color, haircut, shampoo } from "./iconPaths";
import MailIcon from "@material-ui/icons/Mail";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VerifiedIcon from "@mui/icons-material/Verified";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faCoffee } from '@fortawesome/fontawesome-svg-core'
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
//const element = <FontAwesomeIcon icon={faCoffee} />
import { UserContext } from "./content";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Osaamiset } from "../services/OsaamisService";
import Box, { BoxProps } from "@mui/material/Box";

const services = [
  {
    name: "Tilattavaan osaston aiempi työkokemus",
    icon: <GroupAddIcon />,
    iconViewBox: "-20 -20 640.01063 640",
    iconPath: null,
  },
  {
    name: "IV+",
    icon: <VerifiedIcon style={{ fill: "green" }} />,
    iconViewBox: "-20 -20 640 640",
    iconPath: null,
  },
  {
    name: "Diabeteshoitaja",
    icon: <FontAwesomeIcon icon={faCoffee} />,
    iconViewBox: "-50 -20 640 640",
    iconPath: null,
  },
  { name: "Muuta", iconPath: curl, iconViewBox: "-20 -20 640.01038 640" },
  {
    name: "Ja vielä jotain muuta",
    iconPath: straight,
    iconViewBox: "-97 -20 640 640",
  },
];
const getInitialState = () => {
  return services.reduce((obj, item) => {
    obj[item.name] = false;
    return obj;
  }, {});
};
const style = (theme) => ({
  root: {
    padding: 8,
    height: "100%",
    alignItems: "center",
    maxWidth: 500,
    margin: " 0 auto",
  },
  row: { borderBottom: `3px solid ${theme.palette.grey[200]}` },
  icon: {
    fill: theme.palette.grey[700],
    width: 32,
    height: 32,
  },
});

const SelectService = ({ classes }) => {
  const { tiedot, callback } = useContext(UserContext);
  const osaamisetQuery = useQuery(Osaamiset);

  const [serviceSelected, setServiceSelected] = useState([]); //JSON.parse(localStorage.getItem("services")) ?? getInitialState()
  const handleChange = (name) => (e) => {
    //const selected = { ...serviceSelected, [name]: e.target.checked };
    const selected = e.target.checked
      ? [...serviceSelected, name]
      : serviceSelected.filter((service) => service === name);
    setServiceSelected(selected);
    callback({ ...tiedot, osaamiskartta: selected });
    //localStorage.setItem("services", JSON.stringify(selected));
  };

  const textSelected = Object.keys(serviceSelected)
    .filter((key) => serviceSelected[key])
    .join(", ");

  const renderServices = () =>
    services.map((service) => (
      <Grid
        item
        xs={8}
        key={service.name}
        container
        justifyContent="space-between"
        alignItems="right"
        className={classes.row}
      >
        {service.iconPath ? (
          <svg
            className={classes.icon}
            viewBox={service.iconViewBox}
            xmlns="http://www.w3.org/2000/svg"
          >
            {service.iconPath}
          </svg>
        ) : (
          service.icon
        )}
        <FormControlLabel
          control={
            <Switch
              onChange={handleChange(service.name)}
              value={service.name}
              color="primary"
            />
          }
          label={service.name}
          checked={serviceSelected[service.name]}
          labelPlacement="start"
        />
      </Grid>
    ));

  const renderOsaamiset = () => {
    return osaamisetQuery.data?.Osaamiskartat.map((osaaminen, index) => {
      return (
        <Grid
          xs={6}
          item
          key={index}
          container
          justifyContent="space-between"
          alignItems="right"
          className={classes.row}
        >
          <FormControlLabel
            control={
              <Switch
                onChange={handleChange(osaaminen.osaamiskartta_id)}
                value={osaaminen.osaamiskartta_id}
                color="primary"
              />
            }
            //label={` ${osaaminen.lisatieto  ( ${osaaminen.info})}`}
            //label={`${osaaminen.lisatieto} (${osaaminen.kuukausimaara)`}
            label={`${osaaminen.lisatieto} (${osaaminen.kuukausimaara} kk)`}
            checked={serviceSelected[osaaminen.osaamiskartta_id]}
            labelPlacement="start"
          />
        </Grid>
      );
    });
  };

  if (osaamisetQuery.loading) {
    return <p>Ladataan...</p>;
  }

  if (osaamisetQuery.error) return <p>Virhe haettaessa osaamisia</p>;

  return (
    <Box
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        p: 1,
        m: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
      }}
    >
      {/* {renderServices()} */}
      {renderOsaamiset()}

      <input
        type="text"
        value={textSelected}
        name="selected services"
        id="selected-services"
        style={{ width: 1, height: 1, opacity: 0.1 }}
        readOnly
      />
    </Box>
  );
};

SelectService.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(style)(SelectService);
