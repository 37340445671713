import { gql } from "@apollo/client";

export const ToimialueenPalvelualueet = gql`
	query ToimialueenPalvelualueet($toimialue_id: Int!) {
		ToimialueenPalvelualueet(toimialue_id: $toimialue_id) {
			palvelualue_id
			kokonimi
			lyhenne
			lisatieto
		}
	}
`;
