import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import AddIcon from "@material-ui/icons/Add";
import MuiAlert from "@mui/material/Alert";
import {
	Button,
	TextField,
	Grid,
	Fab,
	Divider,
	RadioGroup,
	Radio,
	FormControl,
	FormControlLabel,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
	Box,
} from "@material-ui/core";

import { useQuery, useMutation } from "@apollo/client";
import {
	Nimikkeet,
	TallennaNimike,
	PoistaNimike,
} from "../services/NimikeService";
import { SnackContext } from "../contexts/SnackContext";
import { Nimikeryhmat } from "../services/NimikeRyhmaService";

const Nimike = () => {
	const nimikeQuery = useQuery(Nimikkeet);
	const nimikeryhmaQuery = useQuery(Nimikeryhmat);
	const snack = React.useContext(SnackContext);
	const [nimikeId, setNimikeId] = useState(null);
	const [nimikeryhmaId, setNimikeryhmaId] = useState(null);
	const [kokonimi, setNimike] = useState("");
	const [lyhenne, setLyhenne] = useState("");
	const [lisatieto, setLisatieto] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const [isConfirmVisible, setIsConfirmVisible] = useState(false);

	const [tallennaNimike, tallennaNimikeResult] = useMutation(
		TallennaNimike,
		{ refetchQueries: [Nimikkeet] }
	);

	const [poistaNimike, poistaNimikeResult] = useMutation(PoistaNimike, {
		refetchQueries: [Nimikkeet],
	});

	const tallennaNimikeTiedot = () => {
		snack.setSnack('Nimike tallennettu!');
		if (
			kokonimi !== "" &&
			lyhenne !== "" &&
			nimikeryhmaId !== null

		) {
			const nimike = {
				nimike_id: nimikeId,
				kokonimi,
				lyhenne,
				lisatieto,
				nimikeryhma_id: nimikeryhmaId,
			};
			tallennaNimike({ variables: nimike });
			setIsEditing(false);
			setValittuNimike(null);
		} else {
		}
	};
	/*const tallennaNimikeRyhmaTiedot = () => {
		snack.setSnack('Nimikeryhmä tallennettu!');
		if (
			nimikeryhmaId !== null
		) {
			const nimikeryhma = {
				nimikeryhma_id: nimikeryhmaId,
			};

			tallennaNimikeryhma({ variables: nimikeryhma });
			setIsEditing(false);
			setValittuNimikeRyhma(null);
		} else {
		}
	};*/
	const [openSnack, setOpenSnack] = useState(false);
	const [msg, setMsg] = useState("nothing");

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	  }

	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
		  return;
		}
	
		setOpenSnack(false);
	  };

	const poistaNimikeTiedot = () => {
		if (nimikeId) {
			const nimike = {
				nimike_id: nimikeId,
			};

			poistaNimike({ variables: nimike });
			setIsConfirmVisible(false);
			setValittuNimike(null);
			snack.setSnack('Nimike poistettu','error');
		}
	};

	const renderoiNimikeRyhmaValinnat = () => {
		if (nimikeryhmaQuery.loading || nimikeryhmaQuery.error) {
			return;
		}

		return nimikeryhmaQuery.data.Nimikeryhmat.map((nimikeryhma) => (
			<FormControlLabel
				key={nimikeryhma.nimikeryhma_id}
				value={nimikeryhma.nimikeryhma_id}
				control={<Radio />}
				label={nimikeryhma.lyhenne}
			/>
		));
	};

	const setValittuNimike = (nimike) => {
		setNimikeId(nimike ? nimike.nimike_id : null);
		setNimike(nimike ? nimike.kokonimi : "");
		setLyhenne(nimike ? nimike.lyhenne : "");
		setLisatieto(nimike ? nimike.lisatieto : "");
		setNimikeryhmaId(nimike ? nimike.nimikeryhma.nimikeryhma_id : null);
	};

	const setValittuNimikeRyhma = (nimikeryhma) => {
		setNimikeryhmaId(nimikeryhma ? nimikeryhma.nimikeryhma_id : null);
	};

	if (
		nimikeQuery.loading ||
		tallennaNimikeResult.loading ||
		poistaNimikeResult.loading
	)
		return <p>Ladataan...</p>;
	if (tallennaNimikeResult.error) {
		console.log(tallennaNimikeResult.error.networkError);
		return <p>Virhe tallentaessa nimikettä</p>;
	}
	if (poistaNimikeResult.error) {
		console.log(poistaNimikeResult.error.networkError);
		return <p>Virhe poistettaessa nimikettä</p>;
	}
	if (nimikeQuery.error) return <p>Virhe haettaessa nimikkeitä</p>;
	if (nimikeryhmaQuery.error) return <p>Virhe haettaessa nimikeryhmiä</p>;

	return (
		<div>
			
			<Box sx={{ flexGrow: 3, marginBottom: 10 }}>
				<Fab
					variant="extended"
					size="small"
					color="primary"
					aria-label="add"
					onClick={() => {
						setIsEditing(true);
						setValittuNimike(null);
					}}
				>
					<AddIcon />
				</Fab>
				<Divider orientation="vertical" flexItem />
			</Box>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650, color: "red" }} aria-label="simple table">
					<TableHead sx={{ bgcolor: "blue" }}>
						<TableRow>
							<TableCell sx={{ background: "#d81b60" }}>Nimike</TableCell>
							<TableCell sx={{ background: "#d81b60" }}>Lyhenne</TableCell>
							<TableCell align="right">Lisätieto</TableCell>
							<TableCell align="right" colSpan="2">
								Toiminnot
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{nimikeQuery.data.Nimikkeet.map((nimike) => {
							const {
								nimike_id,
								kokonimi,
								lyhenne,
								lisatieto,
							} = nimike;
							return (
								<TableRow key={nimike_id}>
									<TableCell sx={{ background: "#d81b60" }}>
										{kokonimi}
									</TableCell>
									<TableCell sx={{ background: "#d81b60" }}>
										{lyhenne}
									</TableCell>
									<TableCell align="right">{lisatieto}</TableCell>
									<TableCell align="right">
										<Stack
											spacing={2}
											direction="row"
											justifyContent="flex-end"
										>
											{isEditing ? (
												<>
													<Button
														variant="contained"
														onClick={() => {
															setIsEditing(true);
															setValittuNimike(nimike);
														}}
													>
														Tallenna muutokset
													</Button>
													<Button
														variant="contained"
														onClick={() => {
															setIsEditing(false);
															setValittuNimike(null);
														}}
													>
														Peruuta
													</Button>
												</>
											) : (
												<>
													<Button
														variant="contained"
														onClick={() => {
															setIsEditing(true);
															setValittuNimike(nimike);
														}}
													>
														Muokkaa
													</Button>
													<Button
														variant="contained"
														onClick={() => {
															setValittuNimike(nimike);
															setIsConfirmVisible(true);
														}}
													>
														Poista
													</Button>
												</>
											)}
										</Stack>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
				
			<Dialog
				open={isEditing}
				onClose={() => {
					setIsEditing(false);
					setValittuNimike(null);
					snack.setSnack('Nimike tallennettu');
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
				maxWidth="md"
				PaperProps={{ sx: { width: "50%" } }}
			>
				
				<DialogTitle id="alert-dialog-title">
					{nimikeId ? "Muokkaa nimikettä" : "Lisää uusi nimike"}
				</DialogTitle>
				<DialogContent>
					<FormControl component="fieldset">
						<Grid container direction="row" alignItems="center" spacing={1}>
							<Grid item xs={4}>
								<TextField
									id="utlined-basic"
									label="Nimike"
									variant="outlined"
									padding="10px"
									margin="dense"
									value={kokonimi}
									fullWidth
									onChange={({ target }) => setNimike(target.value)}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									id="outlined-basic"
									label="Lyhenne"
									variant="outlined"
									padding="10px"
									margin="dense"
									width="22"
									value={lyhenne}
									fullWidth
									onChange={({ target }) => setLyhenne(target.value)}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									id="outlined-basic"
									label="Lisätieto"
									variant="outlined"
									padding="10px"
									margin="dense"
									value={lisatieto}
									fullWidth
									onChange={({ target }) => setLisatieto(target.value)}
								/>
							</Grid>
						</Grid>
						<RadioGroup
							row
							aria-label="Nimikeryhmä"
							id="utlined-basic"
							label="Nimike"
							variant="outlined"							
							name="row-radio-buttons-group"
							value={nimikeryhmaId || null}
							onChange={({ target }) => setNimikeryhmaId(Number(target.value))}
						>
							{renderoiNimikeRyhmaValinnat()}
						</RadioGroup>
						<Divider />
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setIsEditing(false);
							setValittuNimike(null);
						}}
					>
						Sulje
					</Button>
					<Button onClick={tallennaNimikeTiedot}>Tallenna</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={isConfirmVisible}

				onClose={() => {
					setIsConfirmVisible(false);
					setValittuNimike(null);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
				maxWidth="md"
				PaperProps={{ sx: { width: "50%" } }}
			>
				<DialogTitle>Nimikkee poisto</DialogTitle>
				<DialogContent>Poistetaanko valittu Nimike</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setIsConfirmVisible(false);
							setValittuNimike(null);
						}}
					>
						Peruuta
					</Button>
					<Button onClick={poistaNimikeTiedot}>
					
						
						Poista</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default Nimike;
