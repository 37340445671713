import React from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState, createContext, useContext } from "react";

import {UserContext} from './selectPalvelualue';
//const palue = useContext(UserContext);

function createData(
   key:Integer,
   alue: string
) {
	return { alue};
}

const palue_1 = [
	createData(1,"Kokkola (Kokkola-Koivuhaka-Kälviä-Lohtaja-Ullava)"),
	createData(2,"Lestijokilaakso (Kannus, Toholampi, Lestijärvi)"),
	createData(3,"Perhonjokilaakso (Veteli, Perho)"),
	
];
const palue_2 =[
	createData(1,"Kehityspoliklinikka ja vammaispalveluiden sosiaalityö"),
	createData(2,"Vammaispalveluiden asumispalvelut ja päiväaikaisen toiminnan palvelut"),
	
];
const palue_3 = [
	createData(1,"Asiakasohjaus ja avopalvelut"),
	createData(2,"Kotihoito"),
	createData(3,"Palveluasuminen ja laitoshoito"),
	createData(4,"Jaksohoito ja tehostettu kotikuntoutuminen"),
	createData(5,"Yleislääketieteen sairaalapalvelut ja geriatria"),
];
const palue_4 =[
	createData(1,"Perhekeskuspalvelut"),
	createData(2,"Lasten ja nuorten erityispalvelut"),
	createData(3,"Mielenterveys- ja päihdepalvelut"),
	createData(4,"Aikuisten sosiaalipalvelut"),

];
const palue_5 = [
	createData(1,"Henkilöstöpalvelut"),
	createData(2,"Talous- ja hallintopalvelut"),
	createData(3,"Tietohallinto"),
	createData(4,"Tila ja tekniset palvelut"),

];

export default function BasicSelect2(props){
  const [alue, setAlue] = React.useState('');
  const [text,setText] =React.useState('Valitse vastuualue');
  console.log(props.name);
  const arvo=props.tulos;
  const value1=props.palue;
  console.log(arvo);
  console.log(props.palue);
  let s=[...palue_3];
  if(arvo===1)
  {
    s=[...palue_1]
  }
 else
  if(arvo===2)
  {
    s=[...palue_2]
  }
  else

  if(arvo===3)
  {
    s=[...palue_3]
  }
  else

  if(arvo===4)
  {
    s=[...palue_4]
  }
  else
  {
    s=[...palue_5]
  }

  const handleChange = (event) => {
    setAlue(event.target.value);
    setText("Valittu");
    console.log(event.target.value);
  
  };

  return (
    
    <Box sx={{ minWidth: 140 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{text}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={alue}
          label="Ehdotus"
          onChange={handleChange}
        >
          
          
          {s.map((row) => (
								<MenuItem value={row.alue}
								 key={row.key}
								
								>{s.alue}
									
								</MenuItem>
							))
          }
              
             

        </Select>
      </FormControl>
    </Box>
  );
}
