import React, { useContext, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import fiLocale from "date-fns/locale/fi";
import {
  withStyles,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { Taustat } from "../services/TaustaService";
import { calendar, clock } from "./iconPaths";
import { UserContext } from "./content";
import { Dialog, FormControl, FormLabel, TableRow } from "@mui/material";
import { DateTime } from "luxon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const dayparts = [
  "Vuosiloma",
  "Hoitovapaa",
  "Opintovapaa",
  "Äitysloma",
  "Omaloma",
  "lomaa",
];

const styles = (theme) => {
  return {
    root: {
      padding: theme.spacing.unit * 3,
      height: "100%",
      maxWidth: 1200,
      margin: " 0 auto",
    },
    icon: {
      height: 28,
      width: 28,
      fill: theme.palette.grey[500],
    },
    input: {
      color: "blue",
    },
  };
};
const useStyles = makeStyles({
  input: {
    color: "blue",
  },
});

const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
    },
  },
})(TextField);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function NaytaYhteenveto() {
  const [getTausta, setTausta] = React.useState(0);
  const { tiedot, callback } = useContext(UserContext);
  const classes = useStyles();
  const taustaQuery = useQuery(Taustat);
  const [selectedDate, setSelectedDate] = useState(
    null //new Date(localStorage.getItem("date") ?? Date.now())
  );

  const [selectedDate2, setSelectedDate2] = useState(
    null // new Date(localStorage.getItem("date2") ?? Date.now())
  );

  const [selectedDaypart, setDaypart] = useState(
    localStorage.getItem("day-part") ?? "nomatter"
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    callback({ ...tiedot, aloituspvm: DateTime.fromJSDate(date).toISODate() });
    localStorage.setItem("date", date);
  };
  const handleDateChange2 = (date2) => {
    setSelectedDate2(date2);
    callback({ ...tiedot, lopetuspvm: DateTime.fromJSDate(date2).toISODate() });
    localStorage.setItem("date2", date2);
  };

  const handleDaypartChange = (e) => {
    //setDaypart(e.target.value);
    localStorage.setItem("day-part", e.target.value);
    callback({ ...tiedot, tausta_id: Number(e.target.value) });
    setTausta(e.target.value);
  };

  const renderTaustaValinnat = () =>
    taustaQuery.data?.Taustat.map((tausta, index) => {
      return (
        <FormControlLabel
          key={index}
          value={tausta.tausta_id}
          control={<Radio color="primary" />}
          label={tausta.kokonimi}
          labelPlacement="bottom"
          checked={Number(getTausta) === tausta.tausta_id}
        />
      );
    });

    return (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            <Box gridColumn="span 6" sx={{bgcolor: "primary. main"}}>
                <Item>Työjakso: {tiedot.aloituspvm} &minus; {tiedot.lopetuspvm}</Item>
            </Box>
            <Box gridColumn="span 6">
                <Item>Toimialue: {tiedot.toimialue_id}</Item>
            </Box>
            <Box gridColumn="span 6">
                <Item>Vastuualue: {tiedot.vastuualue_id}</Item>
            </Box>
            <Box gridColumn="span 6">
                <Item>Palvelualue: {tiedot.palvelualue_id}</Item>
            </Box>
            <Box gridColumn="span 6">
                <Item>Osaamiskartta: {tiedot.osaamiskartta}</Item>
          </Box>
          <Box gridColumn="span 6">
                <Item>Lisätieto: {tiedot.lisatieto}</Item>
          </Box>
          <Box gridColumn="span 6">
                <Item>Vakanssinumero: {tiedot.vakanssinumero}</Item>
          </Box>
          <Box gridColumn="span 6">
                <Item>VYKS: {tiedot.vyks}</Item>
          </Box>
          <Box gridColumn="span 6">
                <Item>Tilaaja: {tiedot.tilaaja}</Item>
          </Box>
          <Box gridColumn="span 6">
                <Item>Tilaajan yhteystieto: {tiedot.tilaaja_yhteystieto}</Item>
          </Box>
        </Box>      
    );
  /*return (
    <Box
      sx={{
        width: "100%",
        height: "280px",
        color: "#fff",
        "& > .MuiBox-root > .MuiBox-root": {
          p: 1,
          borderRadius: 2,
          fontSize: "0.800rem",
          fontWeight: "700",
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: 1,
          gridTemplateRows: "auto",
          gridTemplateAreas: `"header header header header "
        "main main . sidebar"
        "footer footer footer footer"`,
        }}
      >
        <Box container sx={{ gridArea: "header", bgcolor: "primary.main" }}>
          <Grid container item xs={12} spacing={12}>
            <Grid item xs={6} spacing={2}>
              Työjakso: {tiedot.aloituspvm} - {tiedot.lopetuspvm}
              <Grid></Grid>
              Työjakso1: {tiedot.aloituspvm} - {tiedot.lopetuspvm}
            </Grid>
          </Grid>
        </Box>
        <Box container sx={{ gridArea: "sidebar", bgcolor: "primary.main" }}>
          Työjakso1: {tiedot.aloituspvm} - {tiedot.lopetuspvm}
        </Box>
        <Box sx={{ gridArea: "main", bgcolor: "secondary.main" }}>
          <Grid item={3}>
            <Item> {tiedot.aloituspvm}</Item>
            <Item> {tiedot.aloituspvm}</Item>
          </Grid>
        </Box>
        <Box sx={{ bgcolor: "secondary.main" }}>
          <Grid item={3}>
            {tiedot.aloituspvm}
            {tiedot.aloituspvm}
          </Grid>
        </Box>
        <Box sx={{ gridArea: "sidebar", bgcolor: "error.main" }}>
          {tiedot.aloituspvm}
        </Box>
        <Box sx={{ gridArea: "sidebar", bgcolor: "error.main" }}>
          {tiedot.aloituspvm}
        </Box>
        <Box sx={{ gridArea: "footer", bgcolor: "warning.dark" }}>Footer</Box>
      </Box>
    </Box>
  );*/
}

export default withStyles(styles)(NaytaYhteenveto);
