//import * as React from "react";
import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { Avatar, Grid, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { useQuery, useMutation } from "@apollo/client";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { padding } from "@mui/system";
import { blue } from "@mui/material/colors";
import GroupsIcon from "@mui/icons-material/Groups";
import InfoIcon from "@mui/icons-material/Info";
import { SnackContext } from "../contexts/SnackContext";

import { HaeSopimus } from "../services/SopimusService";
//import { TallennaSopimusTehtava } from "../services/HaeTeahtavaService";
import { TallennaSopimusTehtavat } from "../services/TallennaSopimusService";
import { HaeSopimkset } from "../services/SopimusService";
//import { Row } from "@table-library/react-table-library";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 2,
        m: 2,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#000000" : "white.100",
        color: (theme) =>
          theme.palette.mode === "dark" ? "white.300" : "white.800",
        border: "none",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

export default function SopimusTiedot(props) {
  //const { tiedot, callback } = useContext(UserContext);
  const [open, setOpen] = React.useState(true);
  const snack = React.useContext(SnackContext);

  console.log("PROPS", props);

  const [boxi, setBoxi] = React.useState({
    lifecare: true,
    avaimet: false,
    tyosopimus: false,
  });

  const { lifecare, avaimet, tyosopimus } = boxi;
  //const []
  const [selectedDate, setSelectedDate] = useState(
    null //new Date(localStorage.getItem("date") ?? Date.now())
  );
  const [tehtavat, setTehtavat] = useState([]);
  const [tehtavatboxit, setTehtavatboxit] = useState([]);
  const [tallennasopimuste, setTallennasopimuste] = useMutation(
    TallennaSopimusTehtavat,
    { refetchQueries: [HaeSopimkset] }
  );
  const [selectedDate2, setSelectedDate2] = useState(
    null // new Date(localStorage.getItem("date2") ?? Date.now())
  );

  const [selectedDaypart, setDaypart] = useState(
    localStorage.getItem("day-part") ?? "nomatter"
  );

  React.useEffect(() => {
    console.log("sadasdsd", props);
    const mika = [];
    props.sopimus.sopimus_tehtava.forEach((sopimus_tehtava) => {
      console.log(sopimus_tehtava);
      if (sopimus_tehtava.valmis) {
        mika.push({
          sopimus_tehtava_id: sopimus_tehtava.sopimus_tehtava_id,
        });
      }
    });
    setTehtavatboxit(mika);
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    //callback({ ...tiedot, aloituspvm: DateTime.fromJSDate(date).toISODate() });
  };
  const handleDateChange2 = (date2) => {
    setSelectedDate2(date2);
    // callback({ ...tiedot, lopetuspvm: DateTime.fromJSDate(date2).toISODate() });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderoiTehtavat = () => {
    if (props.sopimus.sopimus_tehtava.length === 0) return null;
    console.log("Juu", props.sopimus.sopimus_id);

    return props.sopimus.sopimus_tehtava.map((sopimus_tehtava) => {
      console.log("tasfafs", tehtavatboxit);
      const checkboxtila = tehtavatboxit.find((sopimustehtavaboxi) => {
        return (
          sopimustehtavaboxi.sopimus_tehtava_id ===
          sopimus_tehtava.sopimus_tehtava_id
        );
      });
      console.log(checkboxtila);
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxtila ?? false}
              onChange={(e) =>
                handleChangeboxi(
                  e.target.checked,
                  sopimus_tehtava.sopimus_tehtava_id
                )
              }
            />
          }
          key={sopimus_tehtava.sopimus_tehtava_id}
          label={sopimus_tehtava.tehtava.kokonimi}
        />
      );
    });
  };

  const tallennasopimustehtavat = () => {
    tallennasopimuste({ variables: { sopimusTehtavat: tehtavat } });
    snack.setSnack("Sopimustehtävä tallennettu!");
    props.close();
  };

  const suljesopimustehtavat = () => {
    props.close();
  };

  const handleChangeboxi = (checked, sopimus_tehtava_id) => {
    console.log(sopimus_tehtava_id);
    setTehtavat([
      ...tehtavat,
      {
        sopimus_tehtava_id,
        valmis: checked,
      },
    ]);
    if (checked) {
      setTehtavatboxit([
        ...tehtavatboxit,
        {
          sopimus_tehtava_id,
        },
      ]);
    } else {
      // remove from list
      const juttu = tehtavatboxit.filter(
        (t) => t.sopimus_tehtava_id !== sopimus_tehtava_id
      );
      console.log("lokiiii", juttu);
      setTehtavatboxit(juttu);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.kalle}
        onClose={props.close}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            ></IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Resurssikiinnitys {props.sopimus.sijainen.etunimi}{" "}
              {props.sopimus.sijainen.sukunimi}
            </Typography>
            <Button autoFocus color="inherit" onClick={props.close}>
              Sulje
            </Button>
          </Toolbar>
        </AppBar>

        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              id="outlined-multiline-flexible"
              label={
                props.sopimus.sijainen.etunimi +
                " " +
                props.sopimus.sijainen.sukunimi
              }
              multiline
              maxRows={4}
              value=""
              onChange
            />
          </Grid>
          <Grid item xs={12} sm={12}></Grid>

          <Grid item xs={4} sm={4} spacing={12}>
            <Card sx={{ maxWidth: 1, marginLeft: 1 }}>
              <CardHeader
                titleTypographyProps={{ variant: "h6" }}
                title="Ajanjakso"
                avatar={
                  <Avatar sx={{ bgcolor: blue[500] }}>
                    {" "}
                    <QueryBuilderIcon />
                  </Avatar>
                }
              />
              <CardContent>
                <FormGroup row="false">
                  <InputLabel>Aloituspäivä:</InputLabel>
                  <InputLabel sx={{ color: "black" }}>
                    {props.sopimus.alkupvm}
                  </InputLabel>
                </FormGroup>

                <FormGroup row="false">
                  <InputLabel>Lopetuspäivä:</InputLabel>
                  <InputLabel sx={{ color: "black" }}>
                    {props.sopimus.loppupvm}
                  </InputLabel>
                </FormGroup>
                <p></p>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4} sm={4}>
            <Card>
              <CardHeader
                titleTypographyProps={{ variant: "h6" }}
                title="Kiinnitetty yksikköön"
                avatar={
                  <Avatar sx={{ bgcolor: blue[500] }}>
                    {" "}
                    <GroupsIcon />
                  </Avatar>
                }
              />
              <CardContent>{props.sopimus.vastuualue.lyhenne}</CardContent>
            </Card>
          </Grid>

          <Grid item xs={4} sm={4}>
            <Card>
              <CardHeader
                titleTypographyProps={{ variant: "h6" }}
                title="Lisätiedot"
                avatar={
                  <Avatar sx={{ bgcolor: blue[500] }}>
                    {" "}
                    <InfoIcon />
                  </Avatar>
                }
              />
              <CardContent>{props.sopimus.lisatieto}</CardContent>
            </Card>
          </Grid>

          <Box sx={{ display: "flex" }}>
            <FormControl
              sx={{ m: 6 }}
              component="fieldset"
              variant="standard"
            ></FormControl>
          </Box>
        </Grid>
        <Container maxWidth="sm">
          <FormHelperText>Tarkista sopimuksen muut tehtävät!</FormHelperText>
          <FormGroup aria-label="position" row>
            {renderoiTehtavat()}
          </FormGroup>
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              p: 1,
              m: 1,
            }}
            justifyContent="space-between"
            alignItems="center"
            component="span"
          >
            <Item>
              <Button
                variant="contained"
                color="error"
                onClick={suljesopimustehtavat}
              >
                Peruuta
              </Button>
            </Item>
            <Item>
              <Button
                variant="contained"
                color="success"
                onClick={tallennasopimustehtavat}
              >
                Tallenna
              </Button>
            </Item>
          </Box>
        </Container>
      </Dialog>
    </div>
  );
}
