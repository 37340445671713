import { gql } from "@apollo/client";

export const TallennaSopimus = gql`
  mutation TallennaSopimus(
    $sopimus_id: Int
    $sijainen_id: Int!
    $resurssitilaus_id: Int
    $vastuualue_id: Int!
    $vakanssinumero: String!
    $alkupvm: Date!
    $loppupvm: Date!
    $lisatieto: String
    $aktiivinen: Boolean!
    $arkistoitu: Boolean!
    $tehtavat: [Int]!
  ) {
    TallennaSopimus(
      sopimus_id: $sopimus_id
      sijainen_id: $sijainen_id
      resurssitilaus_id: $resurssitilaus_id
      vastuualue_id: $vastuualue_id
      vakanssinumero: $vakanssinumero
      alkupvm: $alkupvm
      loppupvm: $loppupvm

      lisatieto: $lisatieto
      aktiivinen: $aktiivinen
      arkistoitu: $arkistoitu
      tehtavat: $tehtavat
    ) {
      sopimus_id
      sijainen {
        sijainen_id
      }
      alkupvm
      loppupvm
      aktiivinen
    }
  }
`;

export const TallennaSopimusTehtavat = gql`
  mutation TallennaSopimusTehtavat($sopimusTehtavat: [_Sopimus_Tehtava]!) {
    TallennaSopimusTehtavat(sopimus_tehtavat: $sopimusTehtavat) {
      sopimus_tehtava_id
      valmis
    }
  }
`;

export const ArkistoiSopimus = gql`
  mutation ArkistoiSopimus(
    $sopimus_id: Int!
    $arkistoitu: Boolean!
    ) {
      ArkistoiSopimus(
      sopimus_id: $sopimus_id
      arkistoitu: $arkistoitu
      ) {
        sopimus_id
        arkistoitu
    }
  }
`;