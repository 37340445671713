import React, { useContext, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import fiLocale from "date-fns/locale/fi";
import { TextField } from "@material-ui/core";
import {
  withStyles,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { Taustat } from "../services/TaustaService";
import { calendar, clock } from "./iconPaths";
import { UserContext } from "./content";
import { FormControl, FormLabel } from "@mui/material";
import { DateTime } from "luxon";
//import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const dayparts = [
  "Vuosiloma",
  "Hoitovapaa",
  "Opintovapaa",
  "Äitysloma",
  "Omaloma",
  "lomaa",
];

const styles = (theme) => {
  return {
    root: {
      padding: theme.spacing.unit * 3,
      height: "100%",
      maxWidth: 1200,
      margin: " 0 auto",
    },
    icon: {
      height: 28,
      width: 28,
      fill: theme.palette.grey[500],
    },
  };
};
function SelectDateDaypart({ classes }) {
  const [getTausta, setTausta] = React.useState(0);

  const [getVakanssinumero, setVakanssinumero] = useState(null);
  const [getVYKS, setVYKS] = useState(null);
  const { tiedot, callback } = useContext(UserContext);

  const taustaQuery = useQuery(Taustat);

  const [selectedDate, setSelectedDate] = useState(
    null //new Date(localStorage.getItem("date") ?? Date.now())
  );

  const [selectedDate2, setSelectedDate2] = useState(
    null // new Date(localStorage.getItem("date2") ?? Date.now())
  );

  const [selectedDaypart, setDaypart] = useState(
    localStorage.getItem("day-part") ?? "nomatter"
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    callback({ ...tiedot, aloituspvm: DateTime.fromJSDate(date).toISODate() });
    localStorage.setItem("date", date);
  };
  const handleDateChange2 = (date2) => {
    setSelectedDate2(date2);
    callback({ ...tiedot, lopetuspvm: DateTime.fromJSDate(date2).toISODate() });
    localStorage.setItem("date2", date2);
  };

  const handleVakanssinumeroChange = (e) => {
    const value = e.target.value;
    setVakanssinumero(value);
    callback({ ...tiedot, vakanssinumero: value });
  };

  const handleVYKSChange = (e) => {
    const value = e.target.value;
    setVYKS(value);
    callback({ ...tiedot, vyks: value });
  };

  const handleDaypartChange = (e) => {
    //setDaypart(e.target.value);
    localStorage.setItem("day-part", e.target.value);
    callback({ ...tiedot, tausta_id: Number(e.target.value) });
    setTausta(e.target.value);
  };

  const renderTaustaValinnat = () =>
    taustaQuery.data?.Taustat.map((tausta, index) => {
      return (
        <FormControlLabel
          key={index}
          value={tausta.tausta_id}
          control={<Radio color="primary" />}
          label={tausta.kokonimi}
          labelPlacement="bottom"
          checked={Number(getTausta) === tausta.tausta_id}
        />
      );
    });

  if (taustaQuery.loading || taustaQuery.loading) {
    return <p>Ladataan...</p>;
  }

  if (taustaQuery.error) return <p>Virhe haettaessa taustoja</p>;

  return (
    <Grid
      container
      justifyContent="left"
      alignItems="left"
      spacing={2}
      className={classes.root}
    >
      <Grid item sm={1}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          className={classes.icon}
        >
          {calendar}
        </svg>
      </Grid>
      <Grid item sm={4}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
          <DatePicker
            inputProps={{ id: "date" }}
            value={selectedDate}
            autoOk
            onChange={handleDateChange}
            name="date"
            id="date"
            fullWidth
            variant="filled"
            label="Alkamispäivä"
            renderInput={(params) => <TextField {...params} />}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={4}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
          <div className={classes.picker}>
            <DatePicker
              inputProps={{ id: "date2" }}
              value={selectedDate2}
              autoOk
              onChange={handleDateChange2}
              name="date2"
              id="date2"
              fullWidth
              variant="filled"
              label="Päättymispäivä"
            />
          </div>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={8} margin={10} container >
        <Grid
          container
          direction="row"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={8}>
            <TextField
              value={getVakanssinumero}
              fullWidth
              margin="normal"
              label="Vakanssinumero"
              variant="filled"
              onChange={handleVakanssinumeroChange}
              //disabled={formSubmitted}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={getVYKS}
              fullWidth
              margin="normal"
              label="VYKS"
              variant="filled"
              onChange={handleVYKSChange}
              //disabled={formSubmitted}
            />
          </Grid>          
        </Grid>
      </Grid>
      <Grid item sm={10}>
        <FormControl>
          <FormLabel>Tausta</FormLabel>
          <RadioGroup
            id="daypart"
            name="daypart"
            value={getTausta}
            onChange={handleDaypartChange}
            row
          >
            {renderTaustaValinnat()}
            {/* {dayparts.map((part) => (
						<FormControlLabel
							key={part}
							value={part}
							control={<Radio color="primary" />}
							label={part}
							labelPlacement="bottom"
							checked={selectedDaypart === part}
						/>
					))} */}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(SelectDateDaypart);
