import React from "react";
import "./styles.css";
import CustomInput from "./components/CustomInput";
import Button from "./components/Button";
import Drawer from "./Drawer";
import { LoginPalvelu } from "./services/LoginService";

import { useLazyQuery } from "@apollo/client";

const Login = () => {
  const [user, setUser] = React.useState(null);
  const [passwd, setPasswd] = React.useState(null);
  const [haeUser, userQuery] = useLazyQuery(LoginPalvelu);
  const handleClick = () => {
    haeUser({ variables: { kayttajatunnus: user, salasana: passwd } });
  };
  const handleChange2 = (e) => {
    setUser(e.currentTarget.value);
  };
  const handleChange3 = (e) => {
    setPasswd(e.currentTarget.value);
  };

  if (userQuery.loading) {
    return <p>Ladataan...</p>;
  }

  if (userQuery.error) return <p>Tietojen haku epäonnistui</p>;
  if (userQuery.data) {
    const taso = userQuery.data.TarkistaKayttaja.taso;
    const list = [
      "Etusivu",
      "Resurssitilaus",
      "Resurssikiinnitykset",
      "Adminhallinta",
    ];
    const list2 = ["Etusivu", "Resurssitilaus"];
    return <Drawer menuItems={taso === "Admin" ? list : list2} />;
  }

  return (
    <div className="App">
      <form className="form">
        <CustomInput
          labelText="Tunnus"
          id="userid"
          formControlProps={{
            fullWidth: true,
          }}
          handleChange={handleChange2}
          type="text"
        />
        <CustomInput
          labelText="Salasana"
          id="password"
          formControlProps={{
            fullWidth: true,
          }}
          handleChange={handleChange3}
          type="password"
        />

        <Button
          onClick={handleClick} // tähän fetch
          type="button"
          color="facebook"
          className="form__custom-button"
        >
          Kirjaudu
        </Button>
      </form>
    </div>
  );
};
export default Login;
