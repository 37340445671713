import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";

const heights = [
  { t: 350, h: 220, r: "green" },
  { t: 170, h: 200, r: "black" },
  { t: 350, h: 180, r: "orange" },
];
const vari = [
  "#451242",
  "#773212",
  "#331242",
  "#003212",
  "#331242",
  "#003212",
  "#331242",
  "#003212",
  "#331242",
  "#003212",
  "#331242",
  "#003212",
  "#331242",
  "#003212",
  "#331242",
];
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  border: "1px solid black",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function BasicMasonry() {
  return (
    <Box sx={{ display: "flex" }}>
      <Masonry columns={4} spacing={1}>
        {heights.map((height, index) => (
          <Item
            key={index}
            sx={{
              width: height.h,
              height: height.t,
              backgroundColor: height.r,
            }}
          >
            Osasto.
            {index + 1}
          </Item>
        ))}
      </Masonry>
    </Box>
  );
}
