import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import * as React from "react";
import Alert from "@mui/material/Alert";
import { Dialog } from "@material-ui/core";

export default function ActionAlerts() {
  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    setOpen(false);
  };
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Dialog open={open} onClose={handleOpen}>
        <Alert
          severity="success"
          action={
            <Button color="inherit" size="small" onClick={handleOpen}>
              OK
            </Button>
          }
        >
          Sijaisen sopimus tallenettu
        </Alert>
      </Dialog>
    </Stack>
  );
}
