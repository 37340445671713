import React, { Component } from 'react';
  
class Details extends React.Component {
  render() {
    // To extract values in variables sent by parent component
    const { name, age } = { ...this.props };
    return (
      <div>
        <h3>Person Details: </h3>
        <ul>
          <li>name={this.props.title} {name}</li>
          <li>age={age}</li>
        </ul>
      </div>
    );
  }
}
  
export default Details;