import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Login from "./Login";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const client = new ApolloClient({
	uri: "https://www.tradeon.fi:3001/api/graphql",
	cache: new InMemoryCache(),
});

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<Login />
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
