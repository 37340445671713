import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import BasicMasonry from "./components/Masonry";
import SopimusLisaa from "./components/SopimusLisaa";
import {
  Container,
  Grid,
  Icon,
  Tooltip,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import Taulukko from "./components/Taulukko";
import KaikkiSijaiset from "./components/KaikkiSijaiset";
import Nimike from "./components/Nimike";
import Sopimukset from "./components/Sopimukset";
import SijaisResurssi from "./sijaisresurssi2";
import Sijaisetnakyma from "./sijasetnakyma";
import { snackbarClasses } from "@mui/material";
import { SnackContext } from "./contexts/SnackContext";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function createData(
  paiva: string,
  nimike: string,
  lyhenne: string,
  info: string
) {
  return { paiva, nimike, lyhenne, info };
}

const rows = [
  createData("09-05-2021", "Sairaanhoitaja EK", "SHE", "Erikoisairaanhoitaja"),
  createData("09-05-2021", "Hammashoitaja", "HH", "Hammashoitaja, TK"),
  createData("09-05-2021", "Sairaanhoitaja ", "SH", "Sairaanhoitaja"),
  createData(
    "09-05-2021",
    "Lähihoitaja ",
    "LH",
    "Lähihoitaja vanhus palveluissa"
  ),
  createData("09-05-2021", "Suuhygienisto", "SUH", "Suuhygienisti TK"),
];

function createDataKust(
  paiva: string,
  osasto: string,
  sijainen: number,
  paivakustannus: number,
  lyhenne: number,
  info: number
) {
  return { paiva, osasto, sijainen, paivakustannus, lyhenne, info };
}

const rowskust = [
  createDataKust("12-04-2021", "Osasto 8", 245.0, 24, 4.0, 1),
  createDataKust("01-04-2021", "Osasto 10", 275.0, 37, 4.3, 1),
  createDataKust("12-07-2021", "Osasto 2", 220.0, 255.0, 24, 6.0, 1),
  createDataKust("01-02-2021", "Osasto 11", 240.0, 67, 4.3, 1),
  createDataKust("15-06-2021", "Osasto 4", 275.0, 49, 3.9, 1),
];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FullScreenDialog(props) {
  console.log(props);
  const [open, setOpen] = React.useState(false);
  const [openN, setOpenN] = React.useState(false);
  const [value, setValue] = React.useState("Controlled");
  const [isActive, setisActive] = React.useState(false);
  const [isActiveC, setActiveC] = React.useState(false);
  const [openNew, setOpenNew] = React.useState(false);
  const [openSopimus, setopenSopimus] = React.useState(false);
  const snack = React.useContext(SnackContext);
  const handleTableOpen = () => {
    setisActive(true);
    setActiveC(false);
  };

  const handleTableClose = () => {
    setisActive(false);
    setActiveC(true);
    console.log("toimaa");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen2 = () => {
    setOpenN(true);
  };
  const handleClickSopimus = () => {
    setopenSopimus(true);
  };
  const handleClose2 = () => {
    setOpenN(false);
  };
  const handleClickOpenNew = () => {
    //setOpenNew(true);
    // props.snackbarOpen(true);
    snack.setSnack("Sijainen tallennettu", "warning");
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", width: "100%", p: 0 }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ p: 12 }}
        >
          <Tab label="Osaamiskartta" {...a11yProps(0)} sx={{ p: 12 }} />

          <Tab label="Nimikkeet" {...a11yProps(1)} />
          <Tab label="Kustannukset" {...a11yProps(2)} />

          <Tab label="Sijaiset" {...a11yProps(4)} />
          <Tab label="Tilaukset" {...a11yProps(5)} />
          <Tab label="Sopimukset" {...a11yProps(6)} />
        </Tabs>
      </Box>
      <Divider />
      <TabPanel value={value} index={0}>
        <Button>
          <Fab
            variant="extended"
            size="small"
            color="secondary"
            aria-label="add"
          >
            <SearchIcon onClick={handleTableClose} />
          </Fab>
        </Button>
        <Button>
          <Fab variant="extended" size="small" color="primary" aria-label="add">
            <AddIcon onClick={handleClickOpenNew} />
          </Fab>
        </Button>
        <Grid container sx={{ p: 255 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Paper sx={{ width: 255 }}>Tähän s uusi toimiva komponentti</Paper>
          </Box>
        </Grid>
        <BasicMasonry />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Nimike />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Button>
          <Fab
            variant="extended"
            size="small"
            color="secondary"
            aria-label="add"
          >
            <SearchIcon onClick={handleTableClose} />
          </Fab>
        </Button>
        <Button>
          <Fab variant="extended" size="small" color="primary" aria-label="add">
            <AddIcon onClick={handleClickOpenNew} />
          </Fab>
        </Button>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, color: "#ffffff" }}
            aria-label="simple table"
          >
            <TableHead sx={{ bgcolor: "blue" }}>
              <TableRow>
                <TableCell sx={{}}>Päiväys</TableCell>
                <TableCell sx={{}} align="right">
                  Osasto
                </TableCell>
                <TableCell sx={{}} align="right">
                  Sijainen/päivähinta
                </TableCell>
                <TableCell sx={{}} align="right">
                  Osasto/päiväkustannus
                </TableCell>
                <TableCell sx={{}} align="right">
                  Päättymispäivämäärä
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowskust.map((row1) => (
                <TableRow
                  key={row1.paiva}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">{row1.paiva}</TableCell>
                  <TableCell align="right">{row1.osasto}</TableCell>
                  <TableCell align="right">{row1.sijainen}</TableCell>
                  <TableCell align="right">{row1.paivakustannus}</TableCell>
                  <TableCell align="right">{row1.lyhenne}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <div>
          <KaikkiSijaiset />
        </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <div>
          <Sijaisetnakyma />
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div>
          <Sijaisetnakyma />
        </div>
      </TabPanel>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Sound
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Dessert (100g serving)</TableCell>
                <TableCell align="right">Calories</TableCell>
                <TableCell align="right">Fat&nbsp;(g)</TableCell>
                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                <TableCell align="right">Protein&nbsp;(g)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.calories}</TableCell>
                  <TableCell align="right">{row.fat}</TableCell>
                  <TableCell align="right">{row.carbs}</TableCell>
                  <TableCell align="right">{row.protein}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <List>
          <ListItem button>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItem>
        </List>
      </Dialog>
      <Dialog
        fullScreen
        open={openN}
        onClose={handleClose2}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose2}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Sijainen
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose2}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <ListItemText
              primary="Saija Sairaanhoitaja"
              secondary="Osasto 8. ; 15.02.2021 - 31.12.2021"
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Pauli Hammaskeiju"
              secondary="Osasto 9. ; 15.05.2021 - 31.10.2021"
            />
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
}
