import { gql } from "@apollo/client";

export const HaeSopimkset = gql`
  query Sopimukset {
    Sopimukset {
      sopimus_id
      sijainen {
        sijainen_id
        etunimi
        sukunimi
      }
      vastuualue {
        vastuualue_id
        lyhenne
      }
      resurssitilaus {
        resurssitilaus_id
        vakanssinumero
        aikaleima
      }
      sopimus_tehtava {
        sopimus_tehtava_id
        valmis
        tehtava {
          tehtava_id
          kokonimi
        }
      }
      vakanssinumero
      alkupvm
      loppupvm
      lisatieto
      aktiivinen
      arkistoitu
    }
  }
`;

export const HaeSopimus = gql`
  query Sopimus($s_id: Int!) {
    Sopimus(sopimus_id: $s_id) {
      sopimus_id
      sijainen {
        sijainen_id
        etunimi
        sukunimi
      }
      vastuualue {
        vastuualue_id
        lyhenne
      }
      resurssitilaus {
        resurssitilaus_id
        vakanssinumero
        aikaleima
      }
      sopimus_tehtava {
        valmis
        sopimus_tehtava_id
        tehtava {
          tehtava_id
          kokonimi
        }
      }

      alkupvm
      loppupvm
      lisatieto
      aktiivinen
      arkistoitu
    }
  }
`;
export const HaeSopimukset = gql`
  query SijaistenSopimukset {
    SijaistenSopimukset {
      sijainen_id
      etunimi
      sukunimi
      puhelin
      sopimus {
        sopimus_id
        alkupvm
        loppupvm
        lisatieto
        aktiivinen
        vakanssinumero

        vastuualue {
          kokonimi
          lyhenne
        }
      }
    }
  }
`;
export const TallennaSopimkset = gql`
  mutation TallennaSopimus(
    $sopimus_id: Int
    $sijainen_id: Int!
    $resurssitilaus_id: Int!
    $vastuualue_id: Int!
    $alkupvm: Date!
    $loppupvm: Date!
    $lisatieto: String
    $aktiivinen: Int!
    $aikaleima: Int
  ) {
    TallennaSopimus(
      sopimus_id: $sopimus_id
      sijainen_id: $sijainen_id
      resurssitilaus_id: $resurssitilaus_id
      vastuualue_id: vastuualue_id
      alkupvm: $alkupvm
      loppupvm: $loppupvm
      lisatieto: $lisatieto
      aktiivinen: $aktiivinen
      aikaleima: $aikaleima
    ) {
      sopimus_id
      sijainen_id
      alkupvm
      loppupvm
      aktiivinen
    }
  }
`;
