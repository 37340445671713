import * as React from "react";
import { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import {
	Button,
	Container,
	Grid,
	Icon,
	Tooltip,
	FormHelperText,
	InputLabel,
} from "@material-ui/core";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { styled } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import PersonIcon from "@material-ui/icons/Person";
import Fab from "@material-ui/core/Fab";
import NavigationIcon from "@material-ui/icons//Navigation";
import SearchIcon from "@material-ui/icons/Search";
import AppBar from "@material-ui/core/AppBar";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
//import MuiTableHead from "@material-ui/core/TableHead";
//import AddIcon from '@material-ui/icons/AddIcon';
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
//import ListItemButton from '@material-ui/core/ListItemButton';
import StarIcon from "@material-ui/icons/Star";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//import { ListItemButton } from '@material-ui/core';
import MakeTable from "./TableLuonti";
import Menu from "./Menu";
import DirectionSnackbar from "./Snack";

import InputAdornment from "@material-ui/core/InputAdornment";
import KurssiCard from "./KurssiCard";

import { AccessAlarm, ThreeDRotation, Build } from "@material-ui/icons";
import { TallennaSijainen } from "./services/SijainenService";
import { Nimikkeet } from "./services/NimikeService";
import { useMutation, useQuery } from "@apollo/client";

const Demo = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
}));

const menu = [
	<Build style={{ fill: "green" }} />,
	<AccessTimeIcon />,
	<AccessAlarm />,
	<ThreeDRotation style={{ fill: "red" }} />,
];

function generate(element: React.ReactElement) {
	return [0, 1, 2].map((value) =>
		React.cloneElement(element, {
			key: value,
		})
	);
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const Tablehead = makeStyles(({ theme }) => ({
	table: {
		backgroundColor: "red",
	},
}));

const TableHead1 = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function Clock(props) {
	return (
		<div id="kello">
			<h2>{props.date.toLocaleTimeString()}</h2>
		</div>
	);
}

function avaaDialog(props) {
	return (
		<div>
			<Dialog></Dialog>
		</div>
	);
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 1 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}
function createData(
	osasto: string,
	lyhenne: number,
	esimies: number,
	yhteystieto: number,
	info: number
) {
	return { osasto, lyhenne, esimies, yhteystieto, info };
}

const rows = [
	createData("12-05-2021", 159, 6.0, 24, 4.0),
	createData("01-04-2021", 237, 9.0, 37, 4.3),
	createData("15-04-2021", 262, 16.0, 24, 6.0),
	createData("Psykiatrinen osasto", 305, 3.7, 67, 4.3),
	createData("AnnaWilhelmiina hoivakoti", 356, 16.0, 49, 3.9),
];
function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function BasicTabs() {
	//const [value, setValue] = React.useState(0);
	//const [value, setValue] = React.useState('Controlled');
	const [openNew, setOpenNew] = React.useState(false);
	const [dense, setDense] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [openTable, setOpenTable] = React.useState(false);
	const [value, setValue] = React.useState("Controlled");
	const [isActive, setisActive] = React.useState(false);
	const [isActiveC, setActiveC] = React.useState(false);

	const [etunimi, setEtunimi] = useState("");
	const [sukunimi, setSukunimi] = useState("");
	const [puhelin, setPuhelin] = useState("");
	const [sahkoposti, setSahkoposti] = useState("");
	const [lahiosoite, setLahiosoite] = useState("");
	const [postinumero, setPostinumero] = useState("");
	const [postitoimipaikka, setPostitoimipaikka] = useState("");
	const [nimike, setNimike] = useState("");

	const [tallennaSijainen, { data, loading, error }] =
		useMutation(TallennaSijainen);

	const nimikeQuery = useQuery(Nimikkeet);
	const [secondary, setSecondary] = React.useState(false);
	const handleTableOpen = () => {
		setisActive(true);
		setActiveC(false);
	};
	const handleTableClose = () => {
		setisActive(false);
		setActiveC(true);
		console.log("toimaa");
	};
	const handleTableOpen2 = () => {
		setActiveC(false);
	};
	const handleTableClose2 = () => {
		setisActive(false);
		console.log("toimaa");
	};

	const increaseByOne = () => setisActive(false);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};
	const handleClickOpenNew = () => {
		setOpenNew(true);
	};

	const handleOpen = () => {
		console.log("Open");
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleCloseNew = () => {
		setOpenNew(false);
	};

	const uusiSijainen = () => {
		if (
			etunimi !== "" &&
			sukunimi !== "" &&
			puhelin !== "" &&
			puhelin !== "" &&
			sahkoposti !== "" &&
			lahiosoite !== "" &&
			postinumero !== "" &&
			postitoimipaikka !== ""
		) {
			const sijainen = {
				etunimi,
				sukunimi,
				puhelin,
				sahkoposti,
				lahiosoite,
				postinumero,
				postitoimipaikka,
				nimikeId: 1,
			};
			console.log("Tallennetaan Sijainen:", sijainen);

			tallennaSijainen({ variables: sijainen });
		} else {
		}
	};

	const renderoiNimikeValinnat = () => {
		if (nimikeQuery.loading || nimikeQuery.error) {
			return;
		}

		console.log(nimikeQuery);
		return nimikeQuery.data.Nimikkeet.map((nimike) => (
			<FormControlLabel
				value={nimike.nimike_id.toString()}
				control={<Radio />}
				label={nimike.kokonimi}
			/>
		));
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{ borderBottom: 1, borderColor: "divider", width: "100%", p: 0 }}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					sx={{ p: 12 }}
				>
					<Tab label="Kurssit" {...a11yProps(0)} sx={{ p: 12 }} />
					<Tab label="Osastot" {...a11yProps(1)} />
					<Tab label="Nimikkeet" {...a11yProps(2)} />
					<Tab label="Kustannukset" {...a11yProps(3)} />
					<Tab label="Syykoodit" {...a11yProps(4)} />
					<Tab label="Sijaiset" {...a11yProps(5)} />
				</Tabs>
			</Box>
			<Divider />
			<TabPanel value={value} index={0} flexItem>
				<Box sx={{ flexGrow: 1 }}>
					<Box sx={{ flexGrow: 3 }}>
						<Button>
							<Fab
								variant="extended"
								size="small"
								color="secondary"
								aria-label="add"
							>
								<SearchIcon onClick={handleTableClose} />
							</Fab>
						</Button>
						<Button>
							<Fab
								variant="extended"
								size="small"
								color="primary"
								aria-label="add"
							>
								<AddIcon onClick={handleClickOpenNew} />
							</Fab>
						</Button>
						<Divider orientation="vertical" flexItem />
					</Box>
				</Box>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Button>
					<SearchIcon onClick={handleTableClose} />
				</Button>
				<Grid container sx={{ p: 255 }}>
					<Box sx={{ flexGrow: 1 }}>
						<Paper sx={{ width: 255 }}>Tähän s uusi toimiva komponentti</Paper>
					</Box>
				</Grid>
			</TabPanel>
			<TabPanel value={value} index={2}>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650, color: "red" }} aria-label="simple table">
						<TableHead sx={{ bgcolor: "blue" }}>
							<TableRow>
								<TableCell sx={{ background: "#d81b60" }}>Päiväys</TableCell>
								<TableCell align="right">Osasto</TableCell>
								<TableCell align="right">Sijainen</TableCell>
								<TableCell align="right">Päiväkustannus</TableCell>
								<TableCell align="right">Lopetus</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<TableRow
									key={row.osasto}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<TableCell component="th" scope="row">
										{row.osasto}
									</TableCell>
									<TableCell align="right">{row.lyhenne}</TableCell>
									<TableCell align="right">{row.esimies}</TableCell>
									<TableCell align="right">{row.yhteystieto}</TableCell>
									<TableCell align="right">{row.info}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Button>
					<SearchIcon />
				</Button>
				<Grid container sx={{ p: 255 }}>
					<Box sx={{ flexGrow: 1 }}>
						<Paper sx={{ width: 255 }}>Tähän s uusi toimiva komponentti</Paper>
					</Box>
				</Grid>
			</TabPanel>
			<TabPanel value={value} index={3}>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650, color: "red" }} aria-label="simple table">
						<TableHead sx={{ bgcolor: "blue" }}>
							<TableRow>
								<TableCell sx={{ background: "#d81b60" }}>Päiväys</TableCell>
								<TableCell align="right">Osasto</TableCell>
								<TableCell align="right">Sijainen</TableCell>
								<TableCell align="right">Päiväkustannus</TableCell>
								<TableCell align="right">Päättymispäivä</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<TableRow
									key={row.osasto}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<TableCell component="th" scope="row">
										{row.osasto}
									</TableCell>
									<TableCell align="right">{row.lyhenne}</TableCell>
									<TableCell align="right">{row.esimies}</TableCell>
									<TableCell align="right">{row.yhteystieto}</TableCell>
									<TableCell align="right">{row.info}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</TabPanel>
			<TabPanel value={value} index={4}>
				<TableContainer component={Paper}>
					<List
						sx={{ width: "50%", maxWidth: 360, bgcolor: "background.paper" }}
						aria-label="contacts"
					>
						<ListItem disablePadding>
							<Button>
								<ListItemIcon>
									<StarIcon />
								</ListItemIcon>
								<ListItemText primary="Chelsea Otakan" />
							</Button>
						</ListItem>
						<Divider />
						<ListItem disablePadding>
							<Button>
								<ListItemText inset primary="Eric Hoffman" />
							</Button>
						</ListItem>
						<ListItem disablePadding>
							<Button>
								<ListItemIcon>
									<StarIcon />
								</ListItemIcon>
								<ListItemText primary="Chelsea Otakan" />
							</Button>
						</ListItem>
						<ListItem disablePadding>
							<Button>
								<ListItemText inset primary="Eric Hoffman" />
							</Button>
						</ListItem>
						<ListItem disablePadding>
							<Button>
								<ListItemIcon>
									<StarIcon />
								</ListItemIcon>
								<ListItemText primary="Chelsea Otakan" />
								<ListItemText primary="Chelsea Otakan" />
							</Button>
						</ListItem>
						<ListItem disablePadding>
							<Button>
								<ListItemText inset primary="Eric Hoffman" />
								<ListItemText primary="Chelsea Otakan" />
							</Button>
						</ListItem>
						<ListItem disablePadding>
							<Button>
								<ListItemIcon>
									<StarIcon />
								</ListItemIcon>
								<ListItemText primary="Chelsea Otakan" />
							</Button>
						</ListItem>
						<ListItem disablePadding>
							<Button>
								<ListItemText inset primary="Eric Hoffman" />
							</Button>
						</ListItem>
					</List>
				</TableContainer>
			</TabPanel>
			<TabPanel value={value} index={5} flexItem>
				<Box sx={{ flexGrow: 1 }}>
					<Box sx={{ flexGrow: 3 }}>
						<Button onClick={handleTableOpen}>
							<Fab
								variant="extended"
								size="small"
								color="secondary"
								aria-label="add"
							>
								<SearchIcon />
							</Fab>
						</Button>
						<Button onClick={handleOpen}>
							<Fab
								variant="extended"
								size="small"
								color="primary"
								aria-label="add"
							>
								<AddIcon />
							</Fab>
						</Button>
						<Button>
							<Fab
								variant="extended"
								size="small"
								color="primary"
								aria-label="add"
							>
								<ContactPageIcon onClick={handleTableClose} />
							</Fab>
						</Button>
						<Divider orientation="vertical" flexItem />
					</Box>

					{isActive ? null : (
						<div>
							<MakeTable />
						</div>
					)}
				</Box>

				{isActive ? (
					<div>
						<KurssiCard />
					</div>
				) : null}
			</TabPanel>

			<div>
				<Dialog
					open={openNew}
					onClose={handleCloseNew}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-New">
						{"Huomiot ja heräteet"}
						<Divider />
					</DialogTitle>
					<DialogContent>
						<DirectionSnackbar />
						<DialogContentText id="alert-dialog-new">
							<box>{}</box>
						</DialogContentText>
						<Demo>
							<List>
								{[
									"Sijaisuus keskeneräinen",
									" Uusia sijaisuuspyyntöjä",
									"Muu toiminto",
									"Seuraava toiminto",
								].map((text, index) => (
									<ListItem button key={text}>
										<ListItemIcon>{menu[index]}</ListItemIcon>

										<ListItemText secondary={text} />
									</ListItem>
								))}
							</List>
						</Demo>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseNew} autoFocus>
							Sulje
						</Button>
					</DialogActions>
				</Dialog>
			</div>

			<div>
				<Dialog
					//open={open}

					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					fullWidth
					maxWidth="md"
					PaperProps={{ sx: { width: "50%" } }}
				>
					<AppBar>
						<DialogTitle id="alert-dialog-title">
							{"Lisää sijainen"}
						</DialogTitle>
					</AppBar>
					<DialogContent>
						<FormControl component="fieldset">
							<Grid
								container
								direction="row"
								justifyContent="center"
								alignItems="center"
								spacing={1}
							>
								<Grid item xs={6}>
									<TextField
										id="utlined-basic"
										label="Etunimi"
										variant="outlined"
										padding="10px"
										margin="dense"
										value={etunimi}
										onChange={({ target }) => setEtunimi(target.value)}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="outlined-basic"
										label="Sukunimi"
										variant="outlined"
										padding="10px"
										margin="dense"
										width="22"
										value={sukunimi}
										onChange={({ target }) => setSukunimi(target.value)}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="outlined-basic"
										label="Puhelinnumero"
										variant="outlined"
										padding="10px"
										margin="dense"
										value={puhelin}
										onChange={({ target }) => setPuhelin(target.value)}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="outlined-basic"
										label="Sähköposti"
										variant="outlined"
										padding="15px"
										margin="dense"
										value={sahkoposti}
										onChange={({ target }) => setSahkoposti(target.value)}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="outlined-basic"
										label="Lähiosoite"
										variant="outlined"
										padding="15px"
										margin="dense"
										value={lahiosoite}
										onChange={({ target }) => setLahiosoite(target.value)}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="outlined-basic"
										label="Postinumero"
										inputProps={{ maxLength: 5 }}
										type="number"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										padding="15px"
										margin="dense"
										value={postinumero}
										onChange={({ target }) => setPostinumero(target.value)}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="outlined-basic"
										label="Postitoimipaikka"
										variant="outlined"
										padding="15px"
										margin="dense"
										value={postitoimipaikka}
										onChange={({ target }) => setPostitoimipaikka(target.value)}
									/>
								</Grid>
							</Grid>
							<RadioGroup
								row
								aria-label="Nimike"
								name="row-radio-buttons-group"
								defaultValue="Lähihoitaja"
							>
								{renderoiNimikeValinnat()}
								<FormControlLabel
									value="Lähihoitaja"
									control={<Radio />}
									label="Sairaanhoitaja"
								/>

								<FormControlLabel
									value="Hoitaja"
									control={<Radio />}
									label="Lähihoitaja"
								/>
								<FormControlLabel
									value="Nesteytys"
									control={<Radio />}
									label="Hoitaja"
								/>
								<FormControlLabel
									value="Hammashoitaja"
									control={<Radio />}
									label="Hammashoitaja"
								/>
								<FormControlLabel
									value="Nesteytys"
									disabled
									control={<Radio />}
									label="Muuta?"
								/>
							</RadioGroup>
							<Divider />
						</FormControl>
						<DialogContentText id="alert-dialog-description">
							<Box>
								<FormControlLabel
									value="Tähtiluokitus"
									control={<Checkbox defaultChecked />}
									label="Tähtiluokitus"
								/>
								<Divider />
								<TextField
									id="outlined-multiline-flexible"
									label="Multiline"
									multiline
									maxRows={4}
									value={value}
									onChange={handleChange}
								/>
							</Box>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Sulje</Button>
						<Button onClick={uusiSijainen}>Tallenna</Button>
					</DialogActions>
				</Dialog>
			</div>
		</Box>
	);
}
