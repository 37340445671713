import React, { useContext, useState } from "react";
import {
  withStyles,
  Grid,
  ButtonBase,
  FormControlLabel,
  Radio,
  Tooltip,
} from "@material-ui/core";
import classNames from "classnames";
import { UserContext } from "./content";
const masters = [
  {
    name: "Honkaharju",
    //url: "https://images.unsplash.com/photo-1546961329-78bef0414d7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80",
    url: "https://media.istockphoto.com/photos/fi/l%C3%A4%C3%A4k%C3%A4rit-sairaalan-k%C3%A4yt%C3%A4v%C3%A4hoitaja-ty%C3%B6nt%C3%A4%C3%A4-gurney-paarit-s%C3%A4nky%C3%A4-id482858629?k=20&m=482858629&s=612x612&w=0&h=804iFySlGrLbx_ltsXrFqJwAfgN4Uy5snWAQOgFnfuA=",
    tooltipTxt: "Osasto 2.",
  },
  {
    name: "Osasto 4.",
    //url: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80",
    url: "https://media.istockphoto.com/photos/fi/l%C3%A4%C3%A4k%C3%A4rit-k%C3%A4velev%C3%A4t-k%C3%A4yt%C3%A4v%C3%A4n-l%C3%A4pi-sairaalassa-id1139854094?k=20&m=1139854094&s=612x612&w=0&h=91NK8KE6sVxpYlpJE6WC2KA8ny6BA6Hq_JI2L-bJmAk=",
    tooltipTxt: "Osasto 4.",
  },
  {
    name: "Osasto 7.",
    //url: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80",
    url: "https://media.istockphoto.com/photos/nurses-discussing-medical-documents-in-hospital-picture-id493216309?k=20&m=493216309&s=612x612&w=0&h=ir2KyHAwFMU0maq9cDGB31tV6xbvWiaRGpnqaQo5UhA=",
    tooltipTxt: "Osasto 7.",
  },
  {
    name: "Osasto 10.",
    //url: "https://images.unsplash.com/photo-1546961329-78bef0414d7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80",
    url: "https://media.istockphoto.com/photos/nurses-discussing-medical-documents-in-hospital-picture-id493216309?k=20&m=493216309&s=612x612&w=0&h=ir2KyHAwFMU0maq9cDGB31tV6xbvWiaRGpnqaQo5UhA=",
    tooltipTxt: "Osasto 10.",
  },
  {
    name: "Pädi",
    url: "https://media.istockphoto.com/photos/fi/l%C3%A4hte%C3%A4-pelastamaan-lis%C3%A4%C3%A4-ihmishenki%C3%A4-id1162119276?k=20&m=1162119276&s=612x612&w=0&h=torMWtXEp5YbuK-Q300pwcIY7vLNE2Cap6Dgp4u0C5A=",
    tooltipTxt: "Päivystys ",
  },
];
const style = (theme) => {
  // const active = {
  //   filter: "grayscale(0)",
  //   border: `3px solid ${theme.palette.primary.main}`
  // };
  return {
    root: {
      display: "flex",
    },
    mainClass: {
      height: 116,
      width: 116,
      borderRadius: "50%",
      boxShadow: [
        `0px 16px 26px -10px ${theme.palette.primary.main}a5`,
        theme.shadows[5],
      ],
      margin: 8,
      color: theme.palette.primary.light,
      border: `3px solid ${theme.palette.common.white}`,
      filter: "grayscale(1)",
      transition: theme.transitions.create(),
      "&:active": {
        filter: "grayscale(0)",
        border: `3px solid ${theme.palette.primary.main}`,
      },
      "&:hover": {
        filter: "grayscale(0)",
        border: `3px solid ${theme.palette.primary.main}`,
      },
    },
    mainLblClass: {
      color: theme.palette.grey[700],
      textTransform: "uppercase",
      letterSpacing: 1,
      marginBottom: 16,
      cursor: "pointer",
    },
    //class for checked item
    checked: {
      filter: "grayscale(0)",
      border: `3px solid ${theme.palette.primary.main}`,
    },
    LblChecked: { color: theme.palette.primary.main },
    itemInner: {
      height: 100,
      width: 100,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      boxShadow: theme.shadows[9],
    },
    input: {
      cursor: "pointer",
      height: 106,
      width: 106,
      position: "absolute",
      top: -6,
      left: -6,
      opacity: 0,
    },
  };
};

const RadioMasters = ({ classes }) => {
  const [checked, setChecked] = useState(
    localStorage.getItem("master")
      ? JSON.parse(localStorage.getItem("master"))
      : -1
  );
  const { tiedot, callback } = useContext(UserContext);
  const handleChecked = (i) => (e) => {
    const viesti = "Resurssitilaus 2.0";
    const viesti2 = "uuutta tieto";
    const vanha = tiedot.jokutieto;
    callback({ ...tiedot, osasto_id: 1 });
    localStorage.setItem("master", i);
    setChecked(i);
  };

  const itemOuterClass = (i) =>
    classNames({
      [classes.mainClass]: true,
      [classes.checked]: checked === i,
    });
  const labelClass = (i) =>
    classNames({
      [classes.mainLblClass]: true,
      [classes.LblChecked]: checked === i,
    });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      {masters.map((master, i) => (
        <Grid
          item
          xs={6}
          sm={4}
          key={i}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <ButtonBase className={itemOuterClass(i)}>
            <Tooltip title={master.tooltipTxt} placement="top-end">
              <input
                type="radio"
                name="master"
                // id={master.name}
                value={checked === i && master.name}
                checked={checked === i}
                onChange={handleChecked(i)}
                className={classes.input}
              />
            </Tooltip>
            <div
              className={classes.itemInner}
              style={{ backgroundImage: `url(${master.url})` }}
            />
          </ButtonBase>
          <label htmlFor={master.name} className={labelClass(i)}>
            {master.name}
          </label>
        </Grid>
      ))}

      <input
        type="text"
        id="selected-master"
        name="selected master"
        value={checked > -1 ? masters[checked].name : "none"}
        style={{ width: 1, height: 1, opacity: 0.1 }}
        readOnly
      />
    </Grid>
  );
};
export default withStyles(style)(RadioMasters);
