import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import AddIcon from "@material-ui/icons/Add";
import MuiAlert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  TextField,
  Grid,
  Fab,
  Divider,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Box,
} from "@material-ui/core";

import { useQuery, useMutation } from "@apollo/client";
import {
  Sijaiset,
  TallennaSijainen,
  PoistaSijainen,
} from "../services/SijainenService";
import { Nimikkeet } from "../services/NimikeService";
import { Osaamiset } from "../services/OsaamisService";
import { HaeSopimukset } from "../services/SopimusService";
import { SnackContext } from "../contexts/SnackContext";
import { makeStyles, withStyles } from "@mui/material/styles";
import MuiTableHead from "@material-ui/core/TableHead";
import { ArchiveTwoTone } from "@material-ui/icons";
import { ConstructionOutlined } from "@mui/icons-material";

const KaikkiSijaiset = () => {
  const sijaisetQuery = useQuery(Sijaiset);
  const nimikeQuery = useQuery(Nimikkeet);
  const osaamisetQuery = useQuery(Osaamiset);
  const snack = React.useContext(SnackContext);
  const [sijainenId, setSijainenId] = useState(null);
  const [etunimi, setEtunimi] = useState("");
  const [sukunimi, setSukunimi] = useState("");
  const [puhelin, setPuhelin] = useState("");
  const [sahkoposti, setSahkoposti] = useState("");
  const [lahiosoite, setLahiosoite] = useState("");
  const [postinumero, setPostinumero] = useState("");
  const [postitoimipaikka, setPostitoimipaikka] = useState("");
  const [nimikeId, setNimikeId] = useState(null);
  const [osaamisetId, setOsaamisetId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const [tallennaSijainen, tallennaSijainenResult] = useMutation(
    TallennaSijainen,
    { refetchQueries: [Sijaiset] }
  );

  const [poistaSijainen, poistaSijainenResult] = useMutation(PoistaSijainen, {
    refetchQueries: [Sijaiset],
  });

  //const [haeSopimukset, haeSopimuksetResult] = useQuery(HaeSopimukset);
  /*
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const TableHead = withStyles((theme) => ({
    root: {
      backgroundColor: "Orange",
    },
  }))(MuiTableHead);
  */

  const [kaikkinimikket, setKaikkinimikeet] = React.useState(null);
  const [kaikkiosaamiset, setKaikkiosaamiset] = React.useState(null);
  const [inputvalue, setInputValue] = React.useState("");
  const handleLisaa = (event, value) => {
    console.log(value);
    const mnimike = value.map((nimike) => nimike.nimike_id);
    setKaikkinimikeet(mnimike);
  };
  const handleLisaa2 = (event, value) => {
    const mosaamis = value.map((osaaminen) => osaaminen.osaamiskartta_id);
    setKaikkiosaamiset(mosaamis);
  };

  const tallennaSijaisenTiedot = () => {
    if (
      etunimi !== "" &&
      sukunimi !== "" &&
      puhelin !== "" &&
      puhelin !== "" &&
      sahkoposti !== "" &&
      lahiosoite !== "" &&
      postinumero !== "" &&
      postitoimipaikka !== "" &&
      kaikkinimikket !== null &&
      kaikkiosaamiset !== null
    ) {
      console.log(kaikkinimikket);
      const sijainen = {
        sijainen_id: sijainenId,
        etunimi,
        sukunimi,
        puhelin,
        sahkoposti,
        lahiosoite,
        postinumero,
        postitoimipaikka,
        nimikkeet: kaikkinimikket,
        osaamiskartta: kaikkiosaamiset,
      };
      tallennaSijainen({ variables: sijainen });

      setIsEditing(false);
      setValittuSijainen(null);
      snack.setSnack("Sijainen tallennettu!");
    } else {
    }
  };
  const [openSnack, setOpenSnack] = useState(false);
  const [msg, setMsg] = useState("nothing");

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setValittuSijainen(null);
  };

  const poistaSijaisenTiedot = () => {
    if (sijainenId) {
      const sijainen = {
        sijainen_id: sijainenId,
      };

      poistaSijainen({ variables: sijainen });
      setIsConfirmVisible(false);
      setValittuSijainen(null);
      snack.setSnack("Sijainen poistettu", "error");
    }
  };

  const renderoiNimikeValinnat = () => {
    if (nimikeQuery.loading || nimikeQuery.error) {
      return;
    }

    return nimikeQuery.data.Nimikkeet.map((nimike) => (
      <FormControlLabel
        key={nimike.nimike_id}
        value={nimike.nimike_id}
        control={<Radio />}
        label={nimike.kokonimi}
      />
    ));
  };

  const renderoiOsaamisValinnat = () => {
    if (osaamisetQuery.loading || osaamisetQuery.error) {
      return;
    }

    return osaamisetQuery.data.Osaamiset.map((osaamiset) => (
      <FormControlLabel
        key={osaamiset.osaamiskartta_id}
        value={osaamiset.osaamiskartta_id}
        control={<Radio />}
        label={osaamiset.nimike}
      />
    ));
  };

  const setValittuSijainen = (sijainen) => {
    setSijainenId(sijainen ? sijainen.sijainen_id : null);
    setEtunimi(sijainen ? sijainen.etunimi : "");
    setSukunimi(sijainen ? sijainen.sukunimi : "");
    setPuhelin(sijainen ? sijainen.puhelin : "");
    setSahkoposti(sijainen ? sijainen.sahkoposti : "");
    setLahiosoite(sijainen ? sijainen.lahiosoite : "");
    setPostinumero(sijainen ? sijainen.postinumero : "");
    setPostitoimipaikka(sijainen ? sijainen.postitoimipaikka : "");
    setNimikeId(sijainen ? sijainen?.nimike?.nimike_id : null);
    //setOsaaminenId(sijainen ? sijainen?.osaaminen?.osaaminen_id : null);
  };

  if (
    sijaisetQuery.loading ||
    tallennaSijainenResult.loading ||
    poistaSijainenResult.loading
  )
    return <p>Ladataan...</p>;
  if (tallennaSijainenResult.error) {
    return <p>Virhe tallentaessa sijaista</p>;
  }
  if (poistaSijainenResult.error) {
    return <p>Virhe poistettaessa sijaista</p>;
  }
  if (sijaisetQuery.error) return <p>Virhe haettaessa sijaisia</p>;
  // tähän snack joka ilmoittaa virheestä haettaessa sijaisia
  console.log(kaikkinimikket);
  return (
    <div>
      <Box sx={{ flexGrow: 3, marginBottom: 10 }}>
        <Fab
          variant="extended"
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => {
            setIsEditing(true);
            setValittuSijainen(null);
          }}
        >
          <AddIcon />
        </Fab>
        <Divider orientation="vertical" flexItem />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "blue" }}>
              <TableCell sx={{ backgroundColor: "blue" }}>Etunimi</TableCell>
              <TableCell>Sukunimi</TableCell>
              <TableCell align="right">Puhelin</TableCell>
              <TableCell sx={{ bgcolor: "red" }}>Sähköposti</TableCell>
              <TableCell align="right">Lisätieto</TableCell>
              <TableCell align="right">Postinumero</TableCell>
              <TableCell align="right">Postitoimipaikka</TableCell>
              <TableCell align="right">Nimike</TableCell>
              <TableCell align="right" colSpan="2">
                Toiminnot
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sijaisetQuery.data.Sijaiset.map((sijainen) => {
              const {
                sijainen_id,
                etunimi,
                sukunimi,
                puhelin,
                sahkoposti,
                lahiosoite,
                postinumero,
                postitoimipaikka,
                sijainen_nimike,
              } = sijainen;

              return (
                <TableRow key={sijainen_id}>
                  <TableCell sx={{ background: "#d81b60" }}>
                    {etunimi}
                  </TableCell>
                  <TableCell sx={{ background: "#d81b60" }}>
                    {sukunimi}
                  </TableCell>
                  <TableCell align="right">{puhelin}</TableCell>
                  <TableCell align="right">{sahkoposti}</TableCell>
                  <TableCell align="right">{lahiosoite}</TableCell>
                  <TableCell align="right">{postinumero}</TableCell>
                  <TableCell align="right">{postitoimipaikka}</TableCell>
                  <TableCell align="right">
                    {sijainen_nimike.map(
                      (item, i) => sijainen_nimike[i].nimike.lyhenne
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Stack
                      spacing={2}
                      direction="row"
                      justifyContent="flex-end"
                    >
                      {isEditing ? (
                        <>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setIsEditing(true);
                              setValittuSijainen(sijainen);
                            }}
                          >
                            Tallenna muutokset
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setIsEditing(false);
                              setValittuSijainen(null);
                            }}
                          >
                            Peruuta
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setIsEditing(true);
                              setValittuSijainen(sijainen);
                            }}
                          >
                            Muokkaa
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setValittuSijainen(sijainen);
                              setIsConfirmVisible(true);
                            }}
                          >
                            Poista
                          </Button>
                        </>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={isEditing}
        onClose={() => {
          setIsEditing(false);
          setValittuSijainen(null);
          //setOpenSnack(true);
          snack.setSnack("Sijainen tallennettu");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { width: "50%" } }}
      >
        <DialogTitle id="alert-dialog-title">
          {sijainenId ? "Muokkaa sijaista" : "Lisää uusi sijainen"}
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item xs={4}>
                <TextField
                  id="utlined-basic"
                  label="Etunimi"
                  variant="outlined"
                  padding="10px"
                  margin="dense"
                  value={etunimi}
                  fullWidth
                  onChange={({ target }) => setEtunimi(target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  label="Sukunimi"
                  variant="outlined"
                  padding="10px"
                  margin="dense"
                  width="22"
                  value={sukunimi}
                  fullWidth
                  onChange={({ target }) => setSukunimi(target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  label="Puhelinnumero"
                  variant="outlined"
                  padding="10px"
                  margin="dense"
                  value={puhelin}
                  fullWidth
                  onChange={({ target }) => setPuhelin(target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  label="Sähköposti"
                  variant="outlined"
                  padding="15px"
                  margin="dense"
                  value={sahkoposti}
                  fullWidth
                  onChange={({ target }) => setSahkoposti(target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Lisätieto"
                  variant="outlined"
                  padding="15px"
                  margin="dense"
                  value={lahiosoite}
                  fullWidth
                  onChange={({ target }) => setLahiosoite(target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  label="Postinumero"
                  inputProps={{ maxLength: 5 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  padding="15px"
                  margin="dense"
                  value={postinumero}
                  fullWidth
                  onChange={({ target }) => setPostinumero(target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  label="Postitoimipaikka"
                  variant="outlined"
                  padding="15px"
                  margin="dense"
                  value={postitoimipaikka}
                  fullWidth
                  onChange={({ target }) => setPostitoimipaikka(target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={nimikeQuery.data?.Nimikkeet}
                  //options={top100Films}
                  getOptionLabel={(option) => option.kokonimi}
                  onChange={handleLisaa}
                  inputValue={inputvalue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Nimike"
                      placeholder="Nimike"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={osaamisetQuery.data?.Osaamiskartat}
                  //options={top100Films}
                  getOptionLabel={(option) => option.lisatieto}
                  onChange={handleLisaa2}
                  inputValue={inputvalue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Ammatillinen osaaminen"
                      placeholder="Ammatillinen osaaminen"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsEditing(false);
              setValittuSijainen(null);
            }}
          >
            Sulje
          </Button>
          <Button onClick={tallennaSijaisenTiedot}>Tallenna</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isConfirmVisible}
        onClose={() => {
          setIsConfirmVisible(false);
          setValittuSijainen(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { width: "50%" } }}
      >
        <DialogTitle>Sijaisen poisto</DialogTitle>
        <DialogContent>Poistetaanko valittu sijainen</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsConfirmVisible(false);
              setValittuSijainen(null);
            }}
          >
            Peruuta
          </Button>
          <Button onClick={poistaSijaisenTiedot}>Poista</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default KaikkiSijaiset;
